export const translations = {
  uk: {
    'js.currencies.UAH': 'грн',
    'js.currencies.RUB': 'руб',
    'js.currencies.USD': 'usd',
    'js.currencies.EUR': 'eur',
    'js.plurals.days.one': 'день',
    'js.plurals.days.few': 'дні',
    'js.plurals.days.many': 'днів',
    'js.plurals.days.other': 'днів',
    'js.plurals.apartments.one': 'квартира',
    'js.plurals.apartments.few': 'квартири',
    'js.plurals.apartments.many': 'квартир',
    'js.plurals.apartments.other': 'квартир',
    'js.plurals.apartments_sorted.one': 'квартира відсортовані',
    'js.plurals.apartments_sorted.few': 'квартири відсортовані',
    'js.plurals.apartments_sorted.many': 'квартир відсортовані',
    'js.plurals.apartments_sorted.other': 'квартир відсортовані',
    'js.plurals.ads.one': 'оголошення',
    'js.plurals.ads.few': 'оголошення',
    'js.plurals.ads.many': 'оголошень',
    'js.plurals.ads.other': 'оголошень',
    'js.ad_card.status.viewed': 'Переглянуто',
    'js.ad_card.meter_short': 'м',
    'js.ad_card.month_short': 'міс',
    'js.ad_card.space_for_rent.one_room_apartments': '1-кімн.',
    'js.ad_card.space_for_rent.two_room_apartments': '2-кімн.',
    'js.ad_card.space_for_rent.three_room_apartments': '3-кімн.',
    'js.ad_card.space_for_rent.multi_room_apartments': '4+',
    'js.ad_card.space_for_rent.room': 'К',
    'js.ad_card.space_for_rent_on_map.one_room_apartments': '1-кімн.',
    'js.ad_card.space_for_rent_on_map.two_room_apartments': '2-кімн.',
    'js.ad_card.space_for_rent_on_map.three_room_apartments': '3-кімн.',
    'js.ad_card.space_for_rent_on_map.multi_room_apartments': '4+',
    'js.ad_card.space_for_rent_on_map.room': 'кімната',
    'js.ad_newsletter_modal.new_offers_by_email':
      'Отримуйте нові пропозиції поштою',
    'js.ad_newsletter_modal.note_for_newsletter':
      'За наявності оновлень за вашим запитом, ми будемо один раз на день відправляти новинки вам на пошту протягом місяця. Відписатися можна в особистому кабінеті в розділі',
    'js.ad_newsletter_modal.note_for_newsletter_link': 'сповіщення',
    'js.ad_newsletter_modal.select_more_filters':
      'Оберіть більше фільтрів, за вашими параметрами надто багато оголошень',
    'js.ad_newsletter_modal.you_choosed': 'Ви обрали',
    'js.ad_newsletter_modal.email_placeholder': 'Email',
    'js.ad_newsletter_modal.subscribe_button': 'Підписатися',
    'js.ad_newsletter_modal.success_title': 'Підписку оформлено!',
    'js.ad_newsletter_modal.success_text':
      'Тепер нові оголошення по заданим критеріям будуть приходити вам на пошту.',
    'js.ad_newsletter_modal.success_text_subscription_status':
      'Видалити або перевірити статус підписки можна в розділі {section} в особистому кабінеті.',
    'js.ad_newsletter_modal.success_text_subscription_status_section':
      'сповіщення',
    'js.after_create_modal.ready': 'Готово!',
    'js.after_create_modal.sent_for_moderation':
      'Оголошення відправлено на перевірку, скоро вам зателефонує наш модератор.',
    'js.after_create_modal.time_contact':
      'Якщо ви розмістили оголошення з 10 до 19 годин в робочі дні, ми зателефонуємо протягом 1-2 годин.',
    'js.after_create_modal.ad_changing':
      'Якщо ви\u0026nbsp;вносили виправлення в\u0026nbsp;оголошення і\u0026nbsp;вони не\u0026nbsp;потребують додаткової перевірки, ми\u0026nbsp;розмістимо його на\u0026nbsp;сайті без lpdsyrf модератора.',
    'js.after_create_modal.publication_notice':
      'Після перевірки вашого оголошення ми\u0026nbsp;відразу надішлемо емейл з\u0026nbsp;результатами: опубліковано або відхилено.',
    'js.after_create_modal.contact_us':
      'Вам не зателефонувати протягом 24 годин після публікації? Напишіть нам на {email}',
    'js.app_banner.title':
      'А\u0026nbsp;в\u0026nbsp;додатку ще\u0026nbsp;зручніше',
    'js.app_banner.button': 'Завантажити',
    'js.auth_modal.login_title': 'Вхід',
    'js.auth_modal.login_title_text': 'Ви тут вперше? Спочатку',
    'js.auth_modal.login_title_link': 'зареєструйтесь',
    'js.auth_modal.login_fast_social_auth':
      'Швидкий вхід через соціальні мережі',
    'js.auth_modal.register_title': 'Реєстрація',
    'js.auth_modal.register_title_text': 'Ви вже тут були?',
    'js.auth_modal.register_title_link': 'Тоді увійдіть',
    'js.auth_modal.register_fast_social_auth':
      'Швидка реєстрація через соціальні мережі',
    'js.auth_modal.register_success_title': 'Дякуємо за\u0026nbsp;реєстрацію',
    'js.auth_modal.register_success_text':
      'Тепер ви\u0026nbsp;можете розміщувати оголошення та\u0026nbsp;купувати доступ до\u0026nbsp;контактів',
    'js.auth_modal.recovery_title': 'Відновлення пароля',
    'js.auth_modal.recovery_title_link': 'Повернутись',
    'js.auth_modal.recovery_success_title': 'Інструкції надіслано',
    'js.auth_modal.recovery_success_text':
      'Ми\u0026nbsp;відправили новий пароль на\u0026nbsp;вашу електронну пошту.\u003cbr /\u003e Якщо ви\u0026nbsp;не\u0026nbsp;отримали пароль, спробуйте відправити',
    'js.auth_modal.recovery_success_text_link': 'ще раз',
    'js.auth_modal.reset_title': 'Відновлення пароля',
    'js.auth_modal.reset_title_link': 'Вхід',
    'js.auth_modal.reset_success_title': 'Вітаємо!',
    'js.auth_modal.reset_success_text':
      'Ваш пароль успішно змінено.\u003cbr /\u003e Зараз ви будете автоматично авторизовані.',
    'js.auth_modal.email_placeholder': 'Електронна пошта',
    'js.auth_modal.phone_placeholder': 'Номер телефону',
    'js.auth_modal.password_placeholder': 'Пароль',
    'js.auth_modal.new_password_placeholder': 'Новий пароль',
    'js.auth_modal.confirm_password_placeholder': 'Підтвердження пароля',
    'js.auth_modal.sms_code_placeholder': 'SMS пароль',
    'js.auth_modal.by_email_link': 'По емейлу',
    'js.auth_modal.by_phone_link': 'По телефону',
    'js.auth_modal.validation.field_required': "Це поле обов'язкове",
    'js.auth_modal.validation.field_wrong_format':
      'Поле має неправильний формат',
    'js.auth_modal.validation.password_minlength':
      'Пароль повинен бути не менше 1 символу',
    'js.auth_modal.validation.password_equal_to': 'Паролі повинні співпадати',
    'js.auth_modal.accept_terms_of_use': 'Реєструючись, ви приймаєте умови',
    'js.auth_modal.accept_terms_of_use_link': 'угоди користувача',
    'js.auth_modal.accept_terms_of_use_and_agree': 'i погоджуєтеся на',
    'js.auth_modal.accept_terms_of_use_personal_data_link':
      'обробку ваших персональних даних',
    'js.auth_modal.do_not_remember_password':
      "Не пам'ятаєте пароль? Не біда – давайте",
    'js.auth_modal.do_not_remember_password_link': 'відновимо',
    'js.auth_modal.sms_code_success_note':
      'Ми\u0026nbsp;відправили на\u0026nbsp;ваш номер SMS-код',
    'js.auth_modal.sms_code_timer_note':
      'Запросити SMS-код повторно можна буде через {time}',
    'js.auth_modal.sms_code_timer_note_seconds.one': 'секунду',
    'js.auth_modal.sms_code_timer_note_seconds.few': 'секунди',
    'js.auth_modal.sms_code_timer_note_seconds.many': 'секунд',
    'js.auth_modal.sms_code_timer_note_seconds.other': 'секунд',
    'js.auth_modal.or': 'або',
    'js.auth_modal.sign_in_button': 'Увійти',
    'js.auth_modal.sign_up_button': 'Зареєструватися',
    'js.auth_modal.send_password_button': 'Надіслати пароль',
    'js.auth_modal.change_password_button': 'Змінити пароль',
    'js.auth_modal.get_sms_code_button': 'Отримати SMS-код',
    'js.catalog.breadcrumbs.home': 'Головна',
    'js.catalog.breadcrumbs.search': 'Пошук',
    'js.catalog.sorting.ads': 'оголошень',
    'js.catalog.sorting.apartments': 'квартири відсортовані',
    'js.catalog.sorting.apartments_short': 'квартир',
    'js.catalog.sorting.by_date': 'за датою додавання',
    'js.catalog.sorting.by_price': 'за ціною',
    'js.catalog.sorting.by_area': 'за площею',
    'js.catalog.view.list': 'Списком',
    'js.catalog.view.map': 'На мапі',
    'js.catalog.closed_access_card.text':
      '\u003cb\u003eЩе\u0026nbsp;{ads} від власників\u003c/b\u003e є\u0026nbsp;тільки особистому помічникові',
    'js.catalog.closed_access_card.request': 'Залишити заявку',
    'js.catalog.subscription_card.text':
      'Налаштуйте фільтр і\u0026nbsp;\u003cb\u003eотримуйте пропозиції на\u0026nbsp;пошту\u003c/b\u003e',
    'js.catalog.subscription_card.subscribe': 'Підписатися',
    'js.catalog.filter.title': 'Фільтр',
    'js.catalog.filter.reset': 'Скинути',
    'js.catalog.filter.apply': 'Застосувати',
    'js.catalog.filter.rent': 'Оренда',
    'js.catalog.filter.sale': 'Продаж',
    'js.catalog.filter.what': 'Що',
    'js.catalog.filter.apartments': 'Квартири',
    'js.catalog.filter.rooms': 'Кімнати',
    'js.catalog.filter.sex': 'Підходить для',
    'js.catalog.filter.sex_any': 'Всіх',
    'js.catalog.filter.sex_female': 'Дівчат',
    'js.catalog.filter.sex_male': 'Хлопців',
    'js.catalog.filter.where': 'Де',
    'js.catalog.filter.select_in_the_scheme': 'Обрати на схемі',
    'js.catalog.filter.area_on_the_map': 'Область на мапі',
    'js.catalog.filter.in_the_scheme_or_by_name': 'На схемі або за назвою',
    'js.catalog.filter.clear': 'Очистити',
    'js.catalog.filter.number_of_rooms': 'Кількість кімнат',
    'js.catalog.filter.distance_from_subway': 'Відстань від метро',
    'js.catalog.filter.route_type_no_matter': 'Неважливо',
    'js.catalog.filter.route_type_on_foot': 'Пішки',
    'js.catalog.filter.route_type_transport': 'Транспортом',
    'js.catalog.filter.minutes_short': 'хв',
    'js.catalog.filter.price': 'Ціна',
    'js.catalog.filter.from': 'від',
    'js.catalog.filter.to': 'до',
    'js.catalog.filter.source': 'Джерело',
    'js.catalog.filter.only_verified': 'Тільки перевірені',
    'js.catalog.filter.only_verified_tooltip':
      'Оголошення з перевірених джерел, схвалені модератори',
    'js.catalog.filter.additionally': 'Додатково',
    'js.catalog.filter.space': 'Площа в м\u003csup\u003e2\u003c/sup\u003e',
    'js.catalog.filter.floor': 'Поверх',
    'js.catalog.filter.facilities': 'Зручності',
    'js.catalog.filter.features': 'Особливості',
    'js.catalog.load_more': 'Завантажити ще',
    'js.catalog.map.placemark_hint.current_offer': 'актуальна пропозиіця',
    'js.catalog.map.placemark_hint.viewed': 'переглянуто',
    'js.catalog.map.drawer.select_area_button': 'Обвести',
    'js.catalog.map.drawer.exit_button': 'Вийти',
    'js.catalog.map.drawer.clear_button': 'Очистити',
    'js.contacts_button.show_phone_number': 'Телефон',
    'js.contacts_button.owner_phone_number': 'Телефон власника',
    'js.contacts_modal.evaluate_call':
      'Оцініть \u003cspan class="hidden-xs"\u003eдзвінок\u003c/span\u003e',
    'js.contacts_modal.other_owner_contacts':
      'Інші контакти власника: {contacts}',
    'js.contacts_modal.label_not_for_agents': 'Агентам не дзвонити',
    'js.contacts_modal.note_about_prepayment':
      'Друзі, зверніть увагу. Ніколи, ні за яких обставин \u003cspan class="marked"\u003e не передавайте аванс за квартиру до тих пір, поки ви не відвідаєте її особисто і не побачите документи,\u003c/span\u003e підтверджують право власності.',
    'js.contacts_modal.assistant_text':
      'Ця квартира здається нашим перевіреним партнером - приємною кваліфікованою людиною, яка відповість на всі ваші запитання і у всьому вам допоможе. Зрозуміло, ніякої комісії.',
    'js.contacts_modal.call_like_button': 'Все добре',
    'js.contacts_modal.call_dislike_button': 'Поскаржитись',
    'js.contacts_modal.call_dislike_dropdown.reason_not_relevant':
      'Оголошення не актуальне',
    'js.contacts_modal.call_dislike_dropdown.reason_do_not_answer':
      'Не беруть слухавку',
    'js.contacts_modal.call_dislike_dropdown.reason_false_photos':
      'Фотографії неправдиві',
    'js.contacts_modal.call_dislike_dropdown.reason_comission': 'Є комісія',
    'js.contacts_modal.call_dislike_dropdown.reason_another':
      'Інша причина (вкажіть)',
    'js.contacts_modal.call_dislike_form.reason_placeholder': 'Вкажіть причину',
    'js.contacts_modal.call_dislike_form.submit_button': 'Надіслати',
    'js.control_favorite.remove_from_favorites': 'Видалити з обраного',
    'js.control_favorite.add_to_favorites': 'Додати до обраного',
    'js.control_favorite.removing_from_favorites': 'Прибираємо з обраного...',
    'js.control_favorite.adding_to_favorites': 'Додаємо до обраного...',
    'js.control_favorite.auth_dropdown.you_need_to': 'Вам необхідно',
    'js.control_favorite.auth_dropdown.sign_in': 'увійти',
    'js.control_favorite.auth_dropdown.or': 'або',
    'js.control_favorite.auth_dropdown.sign_up': 'зареєструватися',
    'js.control_promo.tooltip_ad_promoted': 'Це оголошення просувається.',
    'js.control_promo.dropdown_title':
      'Це оголошення просувається. Хочете просунути своє?',
    'js.control_promo.dropdown_more_views':
      'У\u0026nbsp;10\u0026nbsp;разів більше переглядів',
    'js.control_promo.dropdown_always_on_top':
      'Завжди нагорі в\u0026nbsp;пошуку',
    'js.control_promo.dropdown_highlighted_in_list':
      'Виділено\u0026nbsp;списку',
    'js.control_promo.dropdown_recommendation':
      'Включено до рекомендацій Locals',
    'js.control_promo.dropdown_order_button': 'Замовити',
    'js.favorite_button.favorited': 'В обраному',
    'js.favorite_button.to_favorites': 'До обраного',
    'js.favorite_button.wait': 'Зачекайте...',
    'js.metro_scheme_modal.apply_button': 'Застосувати',
    'js.metro_scheme_modal.clear_button': 'Очистити всі станції',
    'js.plan_social_button.message':
      'Шукаю квартиру в оренду без комісії на Локалс. Раджу! #locals_ua',
    'js.main_filters.title': 'Оренда квартир без\u0026nbsp;комісії',
    'js.main_filters.in': 'в',
    'js.main_filters.any_subway': 'Будь-яке метро',
    'js.main_filters.room': 'Кімната',
    'js.main_filters.one_room_apartments': 'Однокімнатні квартири',
    'js.main_filters.two_room_apartments': 'Двокімнатні квартири',
    'js.main_filters.three_room_apartments': 'Трикімнатні квартири',
    'js.main_filters.multi_room_apartments': 'Багатокімнатні квартири',
    'js.main_filters.submit_button': 'Знайти оголошення',
    'js.settings_modal.change_avatar': 'Змінити аватарку',
    'js.settings_modal.title_personal_information': 'Особиста інформація',
    'js.settings_modal.title_change_password': 'Змінити пароль',
    'js.settings_modal.title_phone': 'Телефон',
    'js.settings_modal.title_phone_bind': "Прив'язати телефон",
    'js.settings_modal.title_social_accounts': "Прив'язати обліковий запис",
    'js.settings_modal.name_placeholder': "Ім'я",
    'js.settings_modal.email_placeholder': 'Електронна пошта',
    'js.settings_modal.new_password_placeholder': 'Новий пароль',
    'js.settings_modal.confirm_password_placeholder': 'Підтвердження пароля',
    'js.settings_modal.phone_placeholder': 'Номер телефону',
    'js.settings_modal.sms_code_placeholder': 'SMS пароль',
    'js.settings_modal.sms_code_success_note':
      'Ми відправили на ваш номер SMS-код',
    'js.settings_modal.sms_code_timer_note':
      'Змінити номер телефону або запросити SMS-код повторно можна буде через {time}',
    'js.settings_modal.sms_code_timer_note_seconds.one': 'секунду',
    'js.settings_modal.sms_code_timer_note_seconds.few': 'секунди',
    'js.settings_modal.sms_code_timer_note_seconds.many': 'секунд',
    'js.settings_modal.sms_code_timer_note_seconds.other': 'секунд',
    'js.settings_modal.resend_sms_code_link': 'Відправити SMS-код повторно',
    'js.settings_modal.get_sms_code_button': 'Отримати SMS-код',
    'js.settings_modal.change_button': 'Змінити',
    'js.settings_modal.save_button': 'Зберегти',
    'js.settings_modal.contact_us': 'Запитання? Пишіть на {email}',
    'js.settings_modal.validation.password_minlength':
      'Пароль повинен бути не менше 1 символу',
    'js.settings_modal.validation.password_equal_to':
      'Паролі повинні співпадати',
    'js.settings_modal.validation.password_confirmation_required':
      'Повторно введіть пароль',
    'js.settings_modal.validation.password_confirmation_minlength':
      'Пароль повинен бути не менше 1 символу',
    'js.settings_modal.validation.phone_wrong_format':
      'Невірний формат телефону',
    'js.settings_modal.validation.field_wrong_format':
      'Поле має неправильний формат',
    'js.social_button.vkontakte': 'ВконтактІ',
    'js.social_button.facebook': 'Facebook',
    'js.flash_notifier.error_has_occurred_try_again':
      'Сталася помилка! Спробуйте ще раз.',
    'js.flash_notifier.error_has_occurred_refresh_browser':
      'Сталася помилка! Спробуйте оновити сторінку.',
    'js.flash_notifier.error_has_occurred_check_connection':
      "Сталася помилка! Перевірте з'єднання з інтернетом.",
    'js.flash_notifier.could_not_load_all_ads_try_again':
      'Не вдалося завантажити всі оголошення! Спробуйте ще раз.',
    'js.flash_notifier.session_is_outdated':
      'Сесія скінчилася! Спробуйте відновити пароль.',
    'js.flash_notifier.something_went_wrong':
      'Щось пішло не так! Спробуйте трохи пізніше ще раз. Дякую',
    'js.flash_notifier.thanks_for_mark':
      'Дякую за Вашу оцінку! Ви допомагаєте нам зробити сервіс краще',
    'js.flash_notifier.added_to_favorites':
      'Додано до \u003ca href="{link}" title="обраного"\u003eобраного\u003c/a\u003e',
    'js.flash_notifier.there_are_no_panoramas':
      'В заданій точці немає панорам.',
    'js.ad_form.extra_option.living_size': 'Житлова площа',
    'js.ad_form.extra_option.kitchen_size': 'Кухня',
    'js.ad_form.extra_option.apt_number': 'Номер квартири',
    'js.ad_form.extra_option.build_year': 'Рік будівництва',
    'js.ad_form.extra_option.ceiling_height': 'Висота стель',
    'js.ad_form.extra_option.building_type': 'Тип будинку',
    'js.ad_form.extra_option.building_types.brick': 'Цегляний',
    'js.ad_form.extra_option.building_types.monolithic': 'Монолітний',
    'js.ad_form.extra_option.building_types.panel': 'Панельний',
    'js.ad_form.extra_option.building_types.blocky': 'Блоковий',
    'js.ad_form.extra_option.building_types.wood': "Дерев'яний",
    'js.ad_form.extra_option.building_types.stalinist': 'Сталінський',
    'js.ad_form.extra_option.building_types.monolithic_brick':
      'Монолітно-цегляний',
    'js.ad_form.extra_option.propetry_type': 'Тип нерухомості',
    'js.ad_form.extra_option.cadastral_number': 'Кадастровий номер',
    'js.ad_form.step': 'Крок',
    'js.ad_form.or': 'або',
    'js.ad_form.errors.email': 'Email має незадовільний формат',
    'js.ad_form.errors.field_required': "Поле обов'язкове для заповнення",
    'js.ad_form.errors.select_required': 'Необхідно щось вибрати',
    'js.ad_form.errors.image_required': 'Завантажте хоча б 1 зображення',
    'js.ad_form.errors.must_be_numeric':
      'Наступні поля повинні бути числовими: Площа, Поверх, Поверхів в будинку',
    'js.ad_form.errors.greater_than': 'Введіть число більше 0',
    'js.ad_form.errors.phone_wrong_format': 'Невірний формат телефону',
    'js.ad_form.city.label': 'Город',
    'js.ad_form.auth.label': 'Вхід або реєстрація',
    'js.ad_form.auth.footnotes_register': 'Реєструвалися?',
    'js.ad_form.auth.footnotes_sign': 'Увійдіть',
    'js.ad_form.kind.label': 'Оберіть що ви здаєте',
    'js.ad_form.kind.label_sale': 'What do you sell?',
    'js.ad_form.kind.rent_room': 'Кімнату',
    'js.ad_form.kind.rent_apartment': 'Квартиру',
    'js.ad_form.kind.type': 'Виберіть тип оголошення',
    'js.ad_form.address.label': 'Введіть адресу',
    'js.ad_form.address.placeholder': 'Наприклад, Профспілкова, 10',
    'js.ad_form.address.footnote':
      'Будь ласка, перевірте, чи адреса відображається на карті',
    'js.ad_form.subways.label': 'Найближче метро',
    'js.ad_form.district.label': 'Район',
    'js.ad_form.title.title': 'Заголовок',
    'js.ad_form.title.placeholder': 'Наприклад: «Трійка на Патриках».',
    'js.ad_form.description.label': 'Опис',
    'js.ad_form.description.placeholder':
      "Детальний опис – це обов'язковий параметр оголошення. Якщо не знаєте що писати, скористайтеся підказкою на зеленому фоні",
    'js.ad_form.description.footnote':
      "\u003ch5\u003eОписуйте по пунктах:\u003c/h5\u003e\u003cul\u003e\u003cli\u003eБудинок та квартира (чистий під'їзд, високі стелі)\u003c/li\u003e\u003cli\u003eМеблі, побутова техніка, інтернет\u003c/li\u003e\u003cli\u003eІнфраструктура: все, що робить життя зручним (магазини, поліклініки, школи)\u003c/li\u003e\u003cli\u003eТранспорт і парковка (відстань до метро/зупинки, є парковка біля будинку)\u003c/li\u003e\u003cli\u003eСусіди і прийнятий у квартирі спосіб життя (якщо здаєте кімнату)\u003c/li\u003e\u003c/ul\u003e",
    'js.ad_form.images.label': 'Фотографії',
    'js.ad_form.images.cover': 'Обкладинка',
    'js.ad_form.images.select_text':
      "Виберіть файл на своєму комп'ютері або\u0026nbsp;просто перетягніть сюди фотографії",
    'js.ad_form.images.footnote':
      '\u003ch5\u003eВимоги до фотографій:\u003c/h5\u003e\u003cul\u003e\u003cli\u003eВідсутність на них знаків інших сайтів (Авіто, Ціан, Із рук в руки)\u003c/li\u003e\u003cli\u003eНаявність на них квартири або кімнати\u003c/li\u003e\u003cli\u003eПрийнятний розмір (не менше 800px)\u003c/li\u003e\u003cli\u003eКілька чітких фотографій на яких добре видно кімнати, кухню, ванну і т. д.\u003c/li\u003e\u003c/ul\u003e',
    'js.ad_form.images.select_button': 'Обрати фотографії',
    'js.ad_form.sex.label': 'Для кого',
    'js.ad_form.sex.any': 'Не важливо',
    'js.ad_form.sex.female': 'Тільки для дівчат',
    'js.ad_form.sex.male': 'Тільки для хлопців',
    'js.ad_form.rooms.label': 'Кількість кімнат',
    'js.ad_form.options.label': 'Параметри',
    'js.ad_form.options.space': 'Площа',
    'js.ad_form.options.sq': 'м\u003csup\u003e2\u003c/sup\u003e',
    'js.ad_form.options.floor': 'Поверх',
    'js.ad_form.options.floors_amount': 'Поверхів у будинку',
    'js.ad_form.price.label': 'Ціна на місяць',
    'js.ad_form.ext.label': "Додаткові параметри (заповнювати не обов'язково)",
    'js.ad_form.ext.amenities': 'Зручності',
    'js.ad_form.ext.features': 'Особливості',
    'js.ad_form.phone.label': 'Телефон',
    'js.ad_form.phone.footnote':
      "Наші модератори зателефонують Вам для перевірки квартири. Будь ласка, будьте на зв'язку.",
    'js.ad_form.phone.protect_title': 'Новинка!',
    'js.ad_form.phone.protect_action': 'Захистіть свій мобільний',
    'js.ad_form.phone.protect_buy': 'Підключити за',
    'js.ad_form.phone.protect_note':
      'Ми включимо тимчасовий телефонний номер з переадресацією на ваш мобільний, який дозволить вам приховати свій реальний номер. Коли ви здасте квартиру, цей тимчасовий номер відключиться. Таким чином ви зможете захистити свій номер від небажаних дзвінків і попадання в базу до ріелторів.',
    'js.ad_form.phone.protect_benefits.first':
      'Спеціальний номер з\u0026nbsp;переадресацією на\u0026nbsp;ваш телефон',
    'js.ad_form.phone.protect_benefits.second':
      'Справжній номер прихований, він не\u0026nbsp;потрапить в\u0026nbsp;бази до\u0026nbsp;ріелторам',
    'js.ad_form.phone.protect_benefits.third':
      'Фільтруємо до\u0026nbsp;95% небажаних дзвінків!',
    'js.ad_form.other_contacts.label': "Ще контакти (не\u0026nbsp;обов'язково)",
    'js.ad_form.other_contacts.footnote':
      'Ви можете залишити свій email, посилання на соціальні мережі або будь-який інший прийнятний спосіб',
    'js.ad_form.other_contacts.placeholder':
      'Наприклад: пишіть мені на фейсбук facebook.com/myname, після чого домовимося по телефону',
    'js.ad_form.plan.label': 'Оберіть тариф',
    'js.ad_form.plan.fast.title': 'Здайте швидше',
    'js.ad_form.plan.fast.desc':
      'У\u0026nbsp;10\u0026nbsp;разів більше переглядів',
    'js.ad_form.plan.fast.text1': 'Виділено в\u0026nbsp;списку',
    'js.ad_form.plan.fast.text2':
      'Завжди нагорі в\u0026nbsp;результатах пошуку',
    'js.ad_form.plan.fast.text3':
      '\u003cb class="color-green"\u003eБонус\u003c/b\u003e: модерація за\u0026nbsp;1\u0026nbsp;годин (в\u0026nbsp;робочі дні з\u0026nbsp;11\u0026nbsp;до\u0026nbsp;19)',
    'js.ad_form.plan.fast.access_common': 'Одразу доступно всім відвідувачам',
    'js.ad_form.plan.fast.access_paid': 'Видно на головній сторінці',
    'js.ad_form.plan.normal.title': 'Звичайне оголошення',
    'js.ad_form.plan.normal.access_paid':
      'Доступно всім відвідувачам через {period} (а спочатку — тільки передплатникам)',
    'js.ad_form.plan.normal.access_common': 'Доступно в загальному пошуку',
    'js.ad_form.plan.normal.checking':
      'Перевіряється в\u0026nbsp;загальній черзі',
    'js.ad_form.plan.normal.free': 'Безкоштовно',
    'js.ad_form.plan.buy': 'Купити',
    'js.ad_form.plan.for': 'за',
    'js.ad_form.actions.save': 'Зберегти',
    'js.ad_form.actions.pend': 'Відправити на перевірку',
    'js.ad_form.editing_note':
      'При редагуванні заголовка, опису, номера телефону або фотографій, оголошення буде знову відправлено на модерацію',
    'js.offer_gallery.photo': 'фото',
    'js.offer_map.view.map': 'Мапа',
    'js.offer_map.view.street': 'Вид з вулиці',
    'js.offer_featuring_form.actions.submit': 'Продовжити',
    'js.react_select.no_results': 'Нічого не знайдено',
    'js.buyer_price.open_button': 'Запропонувати свою ціну',
    'js.buyer_price.title': 'Запропонувати свою ціну',
    'js.buyer_price.phone_placeholder': 'Ваш номер телефону',
    'js.buyer_price.validation.phone_wrong_format': 'Невірний формат телефону',
    'js.payment_form.new_card': 'Нова карта',
    'js.payment_form.linked_cards': "Прив'язані карти",
    'js.payment_form.remove_card': 'видалити карту',
    'js.payment_form.add_new_card': 'додати нову карту',
    'js.payment_form.submit_button': 'оплатити',
    'js.payment_form.or': 'або',
    'js.payment_form.security_text':
      'Ваші платіжні дані захищені технологією PCI DSS',
    'js.payment_form.confirm_remove_card':
      'Ви впевнені, що хочете видалити картку?',
    'js.payment_form.flash_notifier.card_successfully_deleted':
      'Картка була успішно видалена',
    'js.payment_card.number': 'Номер картки',
    'js.payment_card.expiry': 'Термін дії',
    'js.payment_card.cvc': 'СVC/CVV',
    'js.payment_card.remember_card':
      'Запам\'ятати \u003cspan class="hidden-xs"\u003eкарту\u003c/span\u003e',
    'js.payment_card.placeholders.expiry': 'ММ/РР',
    'js.payment_card.errors.number': 'Невірний номер карти',
    'js.payment_card.errors.expiry': 'Невірний термін дії',
    'js.payment_card.tooltips.cvc': 'Три цифри на зворотному боці картки',
    'js.payment_card.tooltips.remember': 'Зберегти карту для наступних покупок',
  },
  en: {
    'js.currencies.UAH': 'uah',
    'js.currencies.RUB': 'rub',
    'js.currencies.USD': 'usd',
    'js.currencies.EUR': 'eur',
    'js.plurals.days.one': 'day',
    'js.plurals.days.other': 'days',
    'js.plurals.apartments.one': 'apartment',
    'js.plurals.apartments.other': 'apartments',
    'js.plurals.apartments_sorted.one': 'sorted apartment',
    'js.plurals.apartments_sorted.other': 'sorted apartments',
    'js.plurals.ads.one': 'ad',
    'js.plurals.ads.other': 'ads',
    'js.ad_card.status.viewed': 'Viewed',
    'js.ad_card.meter_short': 'm',
    'js.ad_card.month_short': 'mo',
    'js.ad_card.space_for_rent.one_room_apartments': '1BR',
    'js.ad_card.space_for_rent.two_room_apartments': '2BR',
    'js.ad_card.space_for_rent.three_room_apartments': '3BR',
    'js.ad_card.space_for_rent.multi_room_apartments': '4+',
    'js.ad_card.space_for_rent.room': 'BR',
    'js.ad_card.space_for_rent_on_map.one_room_apartments': '1BR',
    'js.ad_card.space_for_rent_on_map.two_room_apartments': '2BR',
    'js.ad_card.space_for_rent_on_map.three_room_apartments': '3BR',
    'js.ad_card.space_for_rent_on_map.multi_room_apartments': '4+',
    'js.ad_card.space_for_rent_on_map.room': 'room',
    'js.ad_newsletter_modal.new_offers_by_email':
      'Receive the last offers by email',
    'js.ad_newsletter_modal.note_for_newsletter':
      'If there are new listings matching your search, we\u0026rsquo;ll send you email updates for a month. You can unsubscribe in account settings under',
    'js.ad_newsletter_modal.note_for_newsletter_link': 'notifications',
    'js.ad_newsletter_modal.select_more_filters':
      'Too many results match your search. Select more filters.',
    'js.ad_newsletter_modal.you_choosed': 'You chose',
    'js.ad_newsletter_modal.email_placeholder': 'Email',
    'js.ad_newsletter_modal.subscribe_button': 'Subscribe',
    'js.ad_newsletter_modal.success_title': 'Plan activated!',
    'js.ad_newsletter_modal.success_text':
      'You\u0026rsquo;ll start receiving new listings matching your criteria via email.',
    'js.ad_newsletter_modal.success_text_subscription_status':
      'Cancel or check plan status under {section} in your account settings.',
    'js.ad_newsletter_modal.success_text_subscription_status_section':
      'notifications',
    'js.ad_newsletter_modal.more_subways_button.hide.counting.one':
      'Hide station',
    'js.ad_newsletter_modal.more_subways_button.hide.counting.other':
      'Hide stations',
    'js.after_create_modal.ready': 'Ready!',
    'js.after_create_modal.sent_for_moderation':
      'Listing submitted for moderation. A representative will call you soon.',
    'js.after_create_modal.time_contact':
      'If you submitted your listing on a business day from 10am to 7pm, a representative will call you within 1-2 hours.',
    'js.after_create_modal.ad_changing':
      'If you made changes to your listing or do not need additional review, we\u0026rsquo;ll publish it on the site without having a moderator contact you.',
    'js.after_create_modal.publication_notice':
      'After reviewing your listng, we\u0026rsquo;ll send you an email with your status of published or rejected.',
    'js.after_create_modal.contact_us':
      'Didn\u0026rsquo;t receive a call within 24 hours after posting? Write us at {email}',
    'js.app_banner.title':
      'And in\u0026nbsp;the application is\u0026nbsp;even more convenient',
    'js.app_banner.button': 'Download',
    'js.auth_modal.login_title': 'Login',
    'js.auth_modal.login_title_text': 'First time? Start here',
    'js.auth_modal.login_title_link': 'register',
    'js.auth_modal.login_fast_social_auth': 'Sign up via social network',
    'js.auth_modal.register_title': 'Sign up',
    'js.auth_modal.register_title_text': 'Already have an account?',
    'js.auth_modal.register_title_link': 'Then log in',
    'js.auth_modal.register_fast_social_auth': 'Sign up via social network',
    'js.auth_modal.register_success_title': 'Thanks for signing up!',
    'js.auth_modal.register_success_text':
      'Now you can post listings and activate access to contacts',
    'js.auth_modal.recovery_title': 'Password recovery',
    'js.auth_modal.recovery_title_link': 'Go back',
    'js.auth_modal.recovery_success_title': 'Recovery instructions sent',
    'js.auth_modal.recovery_success_text':
      'We\u0026rsquo;ve sent a new password to your email. \u003cbr /\u003eIf you don\u0026rsquo; receive it, try',
    'js.auth_modal.recovery_success_text_link': 'resending.',
    'js.auth_modal.reset_title': 'Password reset',
    'js.auth_modal.reset_title_link': 'Log in',
    'js.auth_modal.reset_success_title': 'Congratulations!',
    'js.auth_modal.reset_success_text':
      'Your password has been changed successfully. You\u0026rsquo;ll now be logged in automatically.',
    'js.auth_modal.email_placeholder': 'Email',
    'js.auth_modal.phone_placeholder': 'Phone',
    'js.auth_modal.password_placeholder': 'Password',
    'js.auth_modal.new_password_placeholder': 'New password',
    'js.auth_modal.confirm_password_placeholder': 'Confirm password',
    'js.auth_modal.sms_code_placeholder': 'SMS-code',
    'js.auth_modal.by_email_link': 'By email',
    'js.auth_modal.by_phone_link': 'By phone',
    'js.auth_modal.validation.field_required': 'This field is required',
    'js.auth_modal.validation.field_wrong_format':
      'This field is formatted incorrectly',
    'js.auth_modal.validation.password_minlength':
      'Password must be at least 1 character long',
    'js.auth_modal.validation.password_equal_to': 'Passwords must match',
    'js.auth_modal.accept_terms_of_use': 'By signing up, you agree to our',
    'js.auth_modal.accept_terms_of_use_link': 'Terms of Use',
    'js.auth_modal.accept_terms_of_use_and_agree': 'and agree to',
    'js.auth_modal.accept_terms_of_use_personal_data_link':
      'the processing of your personal data',
    'js.auth_modal.do_not_remember_password': 'Forgot your password?',
    'js.auth_modal.do_not_remember_password_link': 'Reset it',
    'js.auth_modal.sms_code_success_note':
      'We\u0026nbsp;have sent an\u0026nbsp;SMS-code to\u0026nbsp;your phone number',
    'js.auth_modal.sms_code_timer_note':
      'You can request the SMS-code again in {time}',
    'js.auth_modal.sms_code_timer_note_seconds.one': 'second',
    'js.auth_modal.sms_code_timer_note_seconds.other': 'seconds',
    'js.auth_modal.or': 'or',
    'js.auth_modal.sign_in_button': 'Log in',
    'js.auth_modal.sign_up_button': 'Sign up',
    'js.auth_modal.send_password_button': 'Send new password',
    'js.auth_modal.change_password_button': 'Change password',
    'js.auth_modal.get_sms_code_button': 'Get SMS code',
    'js.catalog.breadcrumbs.home': 'Home',
    'js.catalog.breadcrumbs.search': 'Search',
    'js.catalog.sorting.ads': 'listings',
    'js.catalog.sorting.apartments': 'sorted apartments',
    'js.catalog.sorting.apartments_short': 'apartments',
    'js.catalog.sorting.by_date': 'by date listed',
    'js.catalog.sorting.by_price': 'by price',
    'js.catalog.sorting.by_area': 'by size',
    'js.catalog.view.list': 'List',
    'js.catalog.view.map': 'On map',
    'js.catalog.subscription_card.text':
      'Save this search and \u003cb\u003ereceive offers via email\u003c/b\u003e',
    'js.catalog.subscription_card.subscribe': 'Activate',
    'js.catalog.filter.title': 'Filter',
    'js.catalog.filter.reset': 'Reset',
    'js.catalog.filter.apply': 'Apply',
    'js.catalog.filter.rent': 'Rent',
    'js.catalog.filter.sale': 'Sale',
    'js.catalog.filter.what': 'What',
    'js.catalog.filter.apartments': 'Apartments',
    'js.catalog.filter.rooms': 'Rooms',
    'js.catalog.filter.sex': 'Suitable for',
    'js.catalog.filter.sex_any': 'Any',
    'js.catalog.filter.sex_female': 'Females',
    'js.catalog.filter.sex_male': 'Males',
    'js.catalog.filter.where': 'Where',
    'js.catalog.filter.select_in_the_scheme': 'Select on map',
    'js.catalog.filter.area_on_the_map': 'Area on the map',
    'js.catalog.filter.in_the_scheme_or_by_name': 'On map or by name',
    'js.catalog.filter.clear': 'Clear',
    'js.catalog.filter.number_of_rooms': 'Number of rooms',
    'js.catalog.filter.distance_from_subway': 'Distance from subway',
    'js.catalog.filter.minutes_short': 'min',
    'js.catalog.filter.price': 'Price',
    'js.catalog.filter.from': 'from',
    'js.catalog.filter.to': 'to',
    'js.catalog.filter.source': 'Source',
    'js.catalog.filter.only_verified': 'Only verified',
    'js.catalog.filter.only_verified_tooltip':
      'Ads from trusted sources approved by moderators',
    'js.catalog.filter.additionally': 'Additional',
    'js.catalog.filter.space': 'Size in m\u003csup\u003e2\u003c/sup\u003e',
    'js.catalog.filter.floor': 'Floor',
    'js.catalog.filter.facilities': 'Amenities',
    'js.catalog.filter.features': 'Features',
    'js.catalog.load_more': 'Load more',
    'js.catalog.map.placemark_hint.current_offer': 'current offer',
    'js.catalog.map.placemark_hint.viewed': 'viewed',
    'js.catalog.map.drawer.select_area_button': 'Обвести',
    'js.catalog.map.drawer.exit_button': 'Exit',
    'js.catalog.map.drawer.clear_button': 'Clear',
    'js.contacts_button.show_phone_number': 'Phone',
    'js.contacts_button.owner_phone_number': 'Owner phone',
    'js.contacts_modal.evaluate_call':
      'Leave a \u003cspan class="hidden-xs"\u003ecall review\u003c/span\u003e',
    'js.contacts_modal.other_owner_contacts':
      'Owner\u0026rsquo;s other contact info',
    'js.contacts_modal.label_not_for_agents': 'No agents',
    'js.contacts_modal.note_about_prepayment':
      'Important: never transfer an advance before viewing the apartment in-person and proof of ownership from the lister.',
    'js.contacts_modal.assistant_text':
      'This apartment is being listed by our trusted partner, who will answer all your questions and help you with anything you need. All without fees, of course.',
    'js.contacts_modal.call_like_button': 'Like',
    'js.contacts_modal.call_dislike_button': 'Complain',
    'js.contacts_modal.call_dislike_dropdown.reason_not_relevant':
      'Irrelevant listing',
    'js.contacts_modal.call_dislike_dropdown.reason_do_not_answer':
      'Does not respond',
    'js.contacts_modal.call_dislike_dropdown.reason_false_photos':
      'Fake photos',
    'js.contacts_modal.call_dislike_dropdown.reason_comission': 'Has fees',
    'js.contacts_modal.call_dislike_dropdown.reason_another':
      'Other reason (write below)',
    'js.contacts_modal.call_dislike_form.reason_placeholder': 'Indicate reason',
    'js.contacts_modal.call_dislike_form.submit_button': 'Submit',
    'js.control_favorite.remove_from_favorites': 'Remove from favorites',
    'js.control_favorite.add_to_favorites': 'Add to favorites',
    'js.control_favorite.removing_from_favorites': 'Removing from favorites',
    'js.control_favorite.adding_to_favorites': 'Adding to favorites...',
    'js.control_favorite.auth_dropdown.you_need_to': 'You need to',
    'js.control_favorite.auth_dropdown.sign_in': 'log in',
    'js.control_favorite.auth_dropdown.or': 'or',
    'js.control_favorite.auth_dropdown.sign_up': 'sign up',
    'js.control_promo.tooltip_ad_promoted': 'This is a featured listing',
    'js.control_promo.dropdown_title':
      'This is a featured listing. Would you like to feature yours?',
    'js.control_promo.dropdown_more_views': '10 times more views',
    'js.control_promo.dropdown_always_on_top': 'Always the top result',
    'js.control_promo.dropdown_highlighted_in_list': 'Highlighted in list',
    'js.control_promo.dropdown_recommendation':
      'Added to The Locals\u0026rsquo; recommendations',
    'js.control_promo.dropdown_order_button': 'Order',
    'js.favorite_button.favorited': 'Favorited',
    'js.favorite_button.to_favorites': 'Add to favorites',
    'js.favorite_button.wait': 'Please wait...',
    'js.metro_scheme_modal.apply_button': 'Apply',
    'js.metro_scheme_modal.clear_button': 'Clear all',
    'js.plan_social_button.message':
      "I'm looking for my fee-free apartment on The Locals. Try it! #locals_ru",
    'js.main_filters.title': 'Fee-free apartments',
    'js.main_filters.in': 'in',
    'js.main_filters.any_subway': 'Any subway',
    'js.main_filters.room': 'Room',
    'js.main_filters.one_room_apartments': 'One-bedroom apartments',
    'js.main_filters.two_room_apartments': 'Two-bedroom apartments',
    'js.main_filters.three_room_apartments': 'Three-bedroom apartments',
    'js.main_filters.multi_room_apartments': 'Many-room apartments',
    'js.main_filters.submit_button': 'Search listings',
    'js.settings_modal.change_avatar': 'Change profile image',
    'js.settings_modal.title_personal_information': 'Personal information',
    'js.settings_modal.title_change_password': 'Change password',
    'js.settings_modal.title_phone': 'Phone',
    'js.settings_modal.title_social_accounts': 'Link your other accounts',
    'js.settings_modal.name_placeholder': 'Name',
    'js.settings_modal.email_placeholder': 'Email',
    'js.settings_modal.new_password_placeholder': 'New password',
    'js.settings_modal.confirm_password_placeholder': 'Confirm password',
    'js.settings_modal.phone_placeholder': 'Phone number',
    'js.settings_modal.sms_code_placeholder': 'SMS-code',
    'js.settings_modal.sms_code_success_note':
      'We\u0026nbsp;have sent an\u0026nbsp;SMS-code to\u0026nbsp;your phone number',
    'js.settings_modal.sms_code_timer_note':
      'You can change yout phone number or request the SMS-code again in {time}',
    'js.settings_modal.sms_code_timer_note_seconds.one': 'second',
    'js.settings_modal.sms_code_timer_note_seconds.other': 'seconds',
    'js.settings_modal.resend_sms_code_link': 'Resend SMS-code',
    'js.settings_modal.get_sms_code_button': 'Get SMS-code',
    'js.settings_modal.change_button': 'Change',
    'js.settings_modal.save_button': 'Save',
    'js.settings_modal.contact_us': 'Have questions? Write us at {email}',
    'js.settings_modal.validation.password_minlength':
      'Password must be at least 1 character long',
    'js.settings_modal.validation.password_equal_to': 'Passwords must match',
    'js.settings_modal.validation.password_confirmation_required':
      'Please confirm your password',
    'js.settings_modal.validation.password_confirmation_minlength':
      'Password must be at least 1 character long',
    'js.settings_modal.validation.phone_wrong_format':
      'Invalid phone number format',
    'js.settings_modal.validation.field_wrong_format':
      'This field is formatted incorrectly',
    'js.social_button.vkontakte': 'VKontake',
    'js.social_button.facebook': 'Facebook',
    'js.flash_notifier.error_has_occurred_try_again':
      'An error has occurred. Please try again.',
    'js.flash_notifier.error_has_occurred_refresh_browser':
      'An error has occurred. Please refresh your browser.',
    'js.flash_notifier.error_has_occurred_check_connection':
      'An error has occurred! Check your internet connection.',
    'js.flash_notifier.could_not_load_all_ads_try_again':
      'Could not load all ads! Please try again.',
    'js.flash_notifier.session_is_outdated':
      'Your session has expired. Please try resetting your password again.',
    'js.flash_notifier.something_went_wrong':
      'Something went wrong. Please try again later.',
    'js.flash_notifier.thanks_for_mark':
      'Thanks for your rating and helping us make our service better.',
    'js.flash_notifier.added_to_favorites':
      'Added to \u003ca href="{link}" title="favorites"\u003efavorites\u003c/a\u003e',
    'js.flash_notifier.there_are_no_panoramas':
      'There are no panoramas at the given point.',
    'js.ad_form.extra_option.living_size': 'Living size',
    'js.ad_form.extra_option.kitchen_size': 'Kitchen size',
    'js.ad_form.extra_option.apt_number': 'Apt number',
    'js.ad_form.extra_option.build_year': 'Build year',
    'js.ad_form.extra_option.ceiling_height': 'Ceiling heightв',
    'js.ad_form.extra_option.building_type': 'Building type',
    'js.ad_form.extra_option.building_types.brick': 'brick',
    'js.ad_form.extra_option.building_types.monolithic': 'monolithic',
    'js.ad_form.extra_option.building_types.panel': 'panel',
    'js.ad_form.extra_option.building_types.blocky': 'blocky',
    'js.ad_form.extra_option.building_types.wood': 'wood',
    'js.ad_form.extra_option.building_types.stalinist': 'stalinist',
    'js.ad_form.extra_option.building_types.monolithic_brick':
      'monolithic_brick',
    'js.ad_form.extra_option.propetry_type': 'Propetry type',
    'js.ad_form.extra_option.cadastral_number': 'Cadastral number',
    'js.ad_form.step': 'Step',
    'js.ad_form.or': 'or',
    'js.ad_form.errors.email': 'Email has incorrect format',
    'js.ad_form.errors.field_required': 'This field is required',
    'js.ad_form.errors.select_required': 'Please select something',
    'js.ad_form.errors.image_required': 'Upload at least 1 image',
    'js.ad_form.errors.must_be_numeric':
      'The following fields must be numeric: Area, Floor, Number of floors',
    'js.ad_form.errors.greater_than': 'Enter a number greater than 0',
    'js.ad_form.errors.phone_wrong_format': 'Invalid phone number format',
    'js.ad_form.city.label': 'City',
    'js.ad_form.auth.label': 'Log in or sign up',
    'js.ad_form.auth.footnotes_register': 'Have you registered?',
    'js.ad_form.auth.footnotes_sign': 'Log in',
    'js.ad_form.kind.label': 'What are you renting?',
    'js.ad_form.kind.label_sale': 'What are you sell?',
    'js.ad_form.kind.rent_room': 'Room',
    'js.ad_form.kind.rent_apartment': 'Apartment',
    'js.ad_form.kind.type': 'Choose ad type',
    'js.ad_form.address.label': 'Enter address',
    'js.ad_form.address.placeholder': 'E.g., Profsoyuznaya, 10',
    'js.ad_form.address.footnote':
      'Please check that your address appears on the map',
    'js.ad_form.subways.label': 'Nearest subway',
    'js.ad_form.district.label': 'Choose district',
    'js.ad_form.title.title': 'Title',
    'js.ad_form.title.placeholder': 'E.g., «3BR at Patriarch Ponds».',
    'js.ad_form.description.label': 'Description',
    'js.ad_form.description.placeholder':
      "Detailed description – this field is required. If you're not sure what to write, refer to the tips in green",
    'js.ad_form.description.footnote':
      '\u003ch5\u003eDescribe the following:\u003c/h5\u003e\u003cul\u003e\u003cli\u003eApartment building and unit (clean entrance, high ceilings)\u003c/li\u003e\u003cli\u003eFurniture, appliances, wi-fi\u003c/li\u003e\u003cli\u003eWhat\u0026rsquo;s nearby: grocery stores, hospitals, schools, etc.\u003c/li\u003e\u003cli\u003eTransport and parking (distance to subway, on-site parking)\u003c/li\u003e\u003cli\u003eNeighbors and lifestyle (if you\u0026rsquo;re renting out the apartment)\u003c/li\u003e\u003c/ul\u003e',
    'js.ad_form.images.label': 'Photos',
    'js.ad_form.images.cover': 'Cover',
    'js.ad_form.images.select_text':
      'Select the file from your computer or simply drag it here',
    'js.ad_form.images.footnote':
      '\u003ch5\u003ePhoto requirements:\u003c/h5\u003e\u003cul\u003e\u003cli\u003eNo watermarks from other sites (Avito, CIAN, etc.)\u003c/li\u003e\u003cli\u003ePhotos must be of apartment or room\u003c/li\u003e\u003cli\u003eAcceptable size (at least 800px)\u003c/li\u003e\u003cli\u003eSeveral clear photos displaying rooms, kitchen, bathrooms, etc.\u003c/li\u003e\u003c/ul\u003e',
    'js.ad_form.images.select_button': 'Select photos',
    'js.ad_form.images.actions.remove': 'Remove',
    'js.ad_form.images.actions.rotate': 'Rotate',
    'js.ad_form.sex.label': 'Suitable for',
    'js.ad_form.sex.any': 'Any',
    'js.ad_form.sex.female': 'Females only',
    'js.ad_form.sex.male': 'Mailes only',
    'js.ad_form.rooms.label': 'Number of rooms',
    'js.ad_form.options.label': 'Options',
    'js.ad_form.options.space': 'Area',
    'js.ad_form.options.sq': 'm\u003csup\u003e2\u003c/sup\u003e',
    'js.ad_form.options.floor': 'Floor',
    'js.ad_form.options.floors_amount': 'Number of floors',
    'js.ad_form.price.label': 'Monthly price',
    'js.ad_form.ext.label': 'Additional information (not required)',
    'js.ad_form.ext.amenities': 'Amenities',
    'js.ad_form.ext.features': 'Features',
    'js.ad_form.phone.label': 'Phone',
    'js.ad_form.phone.footnote':
      'Our representatives will call you to confirm the apartment. Please look out for their call.',
    'js.ad_form.phone.protect_title': 'New!',
    'js.ad_form.phone.protect_action': 'Mask your phone number',
    'js.ad_form.phone.protect_buy': 'Activate for',
    'js.ad_form.phone.protect_note':
      'We\u0026rsquo;ll activate a temporary phone number with forwarding to your mobile phone, which allows you to hide your real number. When your listing is rented, this temporary number will be deactivated. This lets you hide your number from unwanted calls from renters and realtors.',
    'js.ad_form.phone.protect_benefits.first':
      'Special number with forwarding to your phone',
    'js.ad_form.phone.protect_benefits.second':
      'The real number is hidden, it will not get to the base of realtors',
    'js.ad_form.phone.protect_benefits.third':
      'We filter up to 95% of unwanted calls!',
    'js.ad_form.other_contacts.label': 'Additional contact info (not required)',
    'js.ad_form.other_contacts.footnote':
      'You can also leave your email, social network profiles or any other contact method.',
    'js.ad_form.other_contacts.placeholder':
      "E.g., «Write me on Facebook at facebook.com/myname, then let's set up a call»",
    'js.ad_form.plan.label': 'Choose your plan',
    'js.ad_form.plan.fast.title': 'Rent faster',
    'js.ad_form.plan.fast.desc': '10 times more views',
    'js.ad_form.plan.fast.text1': 'Highlighted in results',
    'js.ad_form.plan.fast.text2': 'Top placement in search results',
    'js.ad_form.plan.fast.text3':
      '\u003cb class="color-green"\u003eBonus\u003c/b\u003e: approval in\u0026nbsp;1\u0026nbsp;hour (on\u0026nbsp;weekdays from\u0026nbsp;11\u0026nbsp;to\u0026nbsp;19)',
    'js.ad_form.plan.fast.access_common': 'Visible immediately to all visitors',
    'js.ad_form.plan.fast.access_paid': 'Displayed on the homepage',
    'js.ad_form.plan.normal.title': 'Regular listing',
    'js.ad_form.plan.normal.access_paid':
      'Available to all users after {period} (initially for subscribers only)',
    'js.ad_form.plan.normal.access_common': 'Visible in regular search',
    'js.ad_form.plan.normal.checking': 'Being reviewed in our general queue',
    'js.ad_form.plan.normal.free': 'Free',
    'js.ad_form.plan.buy': 'Activate',
    'js.ad_form.plan.for': 'for',
    'js.ad_form.actions.save': 'Save',
    'js.ad_form.actions.pend': 'Submit for review',
    'js.ad_form.editing_note':
      'When editing the title, description, phone number or photos, the ad will be sent back to moderation.',
    'js.offer_gallery.photo': 'photo',
    'js.offer_map.view.map': 'Map',
    'js.offer_map.view.street': 'From the street',
    'js.offer_featuring_form.actions.submit': 'Continue',
    'js.react_select.no_results': 'Nothing found',
    'js.buyer_price.open_button': 'Suggest your price',
    'js.buyer_price.title': 'Suggest your price',
    'js.buyer_price.phone_placeholder': 'Your phone number',
    'js.buyer_price.validation.phone_wrong_format':
      'Invalid phone number format',
    'js.payment_form.new_card': 'New card',
    'js.payment_form.linked_cards': 'Linked cards',
    'js.payment_form.remove_card': 'remove card',
    'js.payment_form.add_new_card': 'add new card',
    'js.payment_form.submit_button': 'Pay',
    'js.payment_form.or': 'or',
    'js.payment_form.security_text':
      'Your billing information is protected by PCI DSS technology.',
    'js.payment_form.confirm_remove_card':
      'Are you sure you want to remove the card?',
    'js.payment_form.flash_notifier.card_successfully_deleted':
      'The card has been successfully deleted',
    'js.payment_card.number': 'Card number',
    'js.payment_card.expiry': 'Valid thru',
    'js.payment_card.cvc': 'СVC/CVV',
    'js.payment_card.remember_card':
      'Save \u003cspan class="hidden-xs"\u003ecard\u003c/span\u003e',
    'js.payment_card.placeholders.expiry': 'MM/YY',
    'js.payment_card.errors.number': 'Invalid card number',
    'js.payment_card.errors.expiry': 'Invalid expiration date',
    'js.payment_card.tooltips.cvc': 'Three digits on the back of the card',
    'js.payment_card.tooltips.remember': 'Save card for future purchases',
  },
  ru: {
    'js.currencies.UAH': 'грн',
    'js.currencies.RUB': 'руб',
    'js.currencies.USD': 'usd',
    'js.currencies.EUR': 'eur',
    'js.plurals.days.one': 'день',
    'js.plurals.days.few': 'дня',
    'js.plurals.days.many': 'дней',
    'js.plurals.days.other': 'дней',
    'js.plurals.ratings.one': 'оценка',
    'js.plurals.ratings.few': 'оценки',
    'js.plurals.ratings.many': 'оценок',
    'js.plurals.ratings.other': 'оценок',
    'js.plurals.apartments.one': 'квартира',
    'js.plurals.apartments.few': 'квартиры',
    'js.plurals.apartments.many': 'квартир',
    'js.plurals.apartments.other': 'квартир',
    'js.plurals.apartments_sorted.one': 'квартира отсортированы',
    'js.plurals.apartments_sorted.few': 'квартиры отсортированы',
    'js.plurals.apartments_sorted.many': 'квартир отсортированы',
    'js.plurals.apartments_sorted.other': 'квартир отсортированы',
    'js.plurals.ads.one': 'объявление',
    'js.plurals.ads.few': 'объявления',
    'js.plurals.ads.many': 'объявлений',
    'js.plurals.ads.other': 'объявлений',
    'js.ad_card.status.viewed': 'Просмотрено',
    'js.ad_card.meter_short': 'м',
    'js.ad_card.month_short': 'мес',
    'js.ad_card.space_for_rent.one_room_apartments': '1-комн.',
    'js.ad_card.space_for_rent.two_room_apartments': '2-комн.',
    'js.ad_card.space_for_rent.three_room_apartments': '3-комн.',
    'js.ad_card.space_for_rent.multi_room_apartments': '4+',
    'js.ad_card.space_for_rent.room': 'К',
    'js.ad_card.space_for_rent_on_map.one_room_apartments': '1-комн.',
    'js.ad_card.space_for_rent_on_map.two_room_apartments': '2-комн.',
    'js.ad_card.space_for_rent_on_map.three_room_apartments': '3-комн.',
    'js.ad_card.space_for_rent_on_map.multi_room_apartments': '4+',
    'js.ad_card.space_for_rent_on_map.room': 'комната',
    'js.ad_newsletter_modal.new_offers_by_email':
      'Получайте новые предложения по\u0026nbsp;почте',
    'js.ad_newsletter_modal.note_for_newsletter':
      'При наличии обновлений по вашему запросу, мы будем один раз в день отправлять новинки вам на почту в течение месяца. Отписаться можно в личном кабинете в разделе',
    'js.ad_newsletter_modal.note_for_newsletter_link': 'уведомления',
    'js.ad_newsletter_modal.select_more_filters':
      'Выберите больше фильтров, по вашим параметрам слишком много объявлений',
    'js.ad_newsletter_modal.more_subways_button.show.counting.one':
      'и ещё {count} станцию',
    'js.ad_newsletter_modal.more_subways_button.show.counting.few':
      'и ещё {count} станции',
    'js.ad_newsletter_modal.more_subways_button.show.counting.many':
      'и ещё {count} станций',
    'js.ad_newsletter_modal.more_subways_button.hide.counting.one':
      'свернуть станцию',
    'js.ad_newsletter_modal.more_subways_button.hide.counting.few':
      'свернуть станции',
    'js.ad_newsletter_modal.more_subways_button.hide.counting.many':
      'свернуть станции',
    'js.ad_newsletter_modal.you_choosed': 'Вы выбрали',
    'js.ad_newsletter_modal.email_placeholder': 'Email',
    'js.ad_newsletter_modal.subscribe_button': 'Подписаться',
    'js.ad_newsletter_modal.success_title': 'Подписка оформлена!',
    'js.ad_newsletter_modal.success_text':
      'Теперь новые объявления по заданным критериям будут приходить вам на почту.',
    'js.ad_newsletter_modal.success_text_subscription_status':
      'Удалить или проверить статус подписки можно в разделе {section} в личном кабинете.',
    'js.ad_newsletter_modal.success_text_subscription_status_section':
      'уведомления',
    'js.after_create_modal.ready': 'Готово!',
    'js.after_create_modal.sent_for_moderation':
      'Объявление отправлено на\u0026nbsp;модерацию, скоро вам позвонит наш модератор.',
    'js.after_create_modal.time_contact':
      'Если вы\u0026nbsp;разместили объявление с\u0026nbsp;10\u0026nbsp;до\u0026nbsp;19\u0026nbsp;часов в\u0026nbsp;рабочие дни, мы\u0026nbsp;позвоним в\u0026nbsp;течение 1-2\u0026nbsp;часов.',
    'js.after_create_modal.ad_changing':
      'Если вы\u0026nbsp;вносили исправления в\u0026nbsp;объявление и\u0026nbsp;они не\u0026nbsp;требуют дополнительной проверки, мы\u0026nbsp;разместим его на\u0026nbsp;сайте без звонка модератора.',
    'js.after_create_modal.publication_notice':
      'После проверки вашего объявления мы\u0026nbsp;сразу вышлем емейл с\u0026nbsp;результатами: опубликовано или отклонено.',
    'js.after_create_modal.contact_us':
      'Вам не\u0026nbsp;позвонили в\u0026nbsp;течение 24\u0026nbsp;часов после публикации? Напишите нам на\u0026nbsp;{email}',
    'js.app_banner.title': 'А\u0026nbsp;в\u0026nbsp;приложении ещё удобнее',
    'js.app_banner.button': 'Скачать',
    'js.auth_modal.login_title': 'Вход',
    'js.auth_modal.login_title_text': 'Вы здесь впервые? Сначала',
    'js.auth_modal.login_title_link': 'зарегистрируйтесь',
    'js.auth_modal.login_fast_social_auth':
      'Быстрый вход через социальные сети',
    'js.auth_modal.register_title': 'Регистрация',
    'js.auth_modal.register_title_text': 'Вы уже тут были?',
    'js.auth_modal.register_title_link': 'Тогда войдите',
    'js.auth_modal.register_fast_social_auth':
      'Быстрая регистрация через социальные сети',
    'js.auth_modal.register_success_title': 'Спасибо за\u0026nbsp;регистрацию',
    'js.auth_modal.register_success_text':
      'Теперь вы\u0026nbsp;можете размещать объявления и\u0026nbsp;покупать доступ к\u0026nbsp;контактам',
    'js.auth_modal.recovery_title': 'Восстановление пароля',
    'js.auth_modal.recovery_title_link': 'Вернуться',
    'js.auth_modal.recovery_success_title': 'Инструкции отправлены',
    'js.auth_modal.recovery_success_text':
      'Мы\u0026nbsp;отправили новый пароль на\u0026nbsp;вашу электронную почту.\u003cbr /\u003e Если вы\u0026nbsp;не\u0026nbsp;получили пароль, попробуйте отправить',
    'js.auth_modal.recovery_success_text_link': 'еще раз',
    'js.auth_modal.reset_title': 'Восстановление пароля',
    'js.auth_modal.reset_title_link': 'Вход',
    'js.auth_modal.reset_success_title': 'Поздравляем!',
    'js.auth_modal.reset_success_text':
      'Ваш пароль был успешно изменён.\u003cbr /\u003e Сейчас вы будете автоматически авторизированы.',
    'js.auth_modal.email_placeholder': 'Электронная почта',
    'js.auth_modal.phone_placeholder': 'Номер телефона',
    'js.auth_modal.password_placeholder': 'Пароль',
    'js.auth_modal.new_password_placeholder': 'Новый пароль',
    'js.auth_modal.confirm_password_placeholder': 'Подтверждение пароля',
    'js.auth_modal.sms_code_placeholder': 'SMS пароль',
    'js.auth_modal.by_email_link': 'По емейлу',
    'js.auth_modal.by_phone_link': 'По телефону',
    'js.auth_modal.validation.field_required': 'Это поле обязательное',
    'js.auth_modal.validation.field_wrong_format':
      'Поле имеет неправильный формат',
    'js.auth_modal.validation.password_minlength':
      'Пароль должен быть не менее 1 символа',
    'js.auth_modal.validation.password_equal_to': 'Пароли должны совпадать',
    'js.auth_modal.accept_terms_of_use': 'Регистрируясь, вы принимаете условия',
    'js.auth_modal.accept_terms_of_use_link': 'пользовательского соглашения',
    'js.auth_modal.accept_terms_of_use_and_agree': 'и соглашаетесь на',
    'js.auth_modal.accept_terms_of_use_personal_data_link':
      'обработку ваших персональных данных',
    'js.auth_modal.do_not_remember_password':
      'Не помните пароль? Не беда – давайте',
    'js.auth_modal.do_not_remember_password_link': 'восстановим',
    'js.auth_modal.sms_code_success_note':
      'Мы\u0026nbsp;отправили на\u0026nbsp;ваш номер SMS-код',
    'js.auth_modal.sms_code_timer_note':
      'Запросить SMS-код повторно можно будет через {time}',
    'js.auth_modal.sms_code_timer_note_seconds.one': 'секунду',
    'js.auth_modal.sms_code_timer_note_seconds.few': 'секунды',
    'js.auth_modal.sms_code_timer_note_seconds.many': 'секунд',
    'js.auth_modal.sms_code_timer_note_seconds.other': 'секунд',
    'js.auth_modal.or': 'или',
    'js.auth_modal.or_phone_link': 'или телефон',
    'js.auth_modal.or_email_link': 'или почта',
    'js.auth_modal.sign_in_button': 'Войти',
    'js.auth_modal.sign_up_button': 'Зарегистрироваться',
    'js.auth_modal.send_password_button': 'Отправить пароль',
    'js.auth_modal.change_password_button': 'Изменить пароль',
    'js.auth_modal.get_sms_code_button': 'Получить SMS-код',
    'js.catalog.breadcrumbs.home': 'Главная',
    'js.catalog.breadcrumbs.search': 'Поиск',
    'js.catalog.sorting.ads': 'объявлений',
    'js.catalog.sorting.apartments': 'квартиры отсортированы',
    'js.catalog.sorting.apartments_short': 'квартир',
    'js.catalog.sorting.by_date': 'по дате добавления',
    'js.catalog.sorting.by_price': 'по цене',
    'js.catalog.sorting.by_area': 'по площади',
    'js.catalog.view.list': 'Списком',
    'js.catalog.view.map': 'На карте',
    'js.catalog.closed_access_card.title': 'Не пропустите свой вариант',
    'js.catalog.closed_access_card.text':
      'Личный помощник видит объявления еще до того, как они попадут на сайт и сразу предлагает их вам',
    'js.catalog.closed_access_card.request': 'Подробнее',
    'js.catalog.subscription_card.title': 'Рассылка с новыми объявлениями',
    'js.catalog.subscription_card.text':
      'Настройте фильтр и\u0026nbsp;получайте уведомления о\u0026nbsp;подходящих вам квартирах ',
    'js.catalog.subscription_card.request': 'Подписаться',
    'js.catalog.whatsapp_help_card.title': 'Консультация в WhatsApp',
    'js.catalog.whatsapp_help_card.text':
      'Расскажем о сервисе и\u0026nbsp;поможем подобрать подходящий именно вам тариф',
    'js.catalog.whatsapp_help_card.request': 'Перейти в WhatsApp',
    'js.catalog.search_assistant_card.title': 'Надоело искать?',
    'js.catalog.search_assistant_card.text':
      'Укажите требования к\u0026nbsp;квартире и поручите поиск личному\u0026nbsp;помощнику',
    'js.catalog.search_assistant_card.request': 'Оставить заявку',
    'js.catalog.filter.title': 'Фильтр',
    'js.catalog.filter.reset': 'Сбросить',
    'js.catalog.filter.apply': 'Применить',
    'js.catalog.filter.rent': 'Аренда длительно',
    'js.catalog.filter.daily_rent': 'Аренда посуточно',
    'js.catalog.filter.sale': 'Продажа',
    'js.catalog.filter.what': 'Что',
    'js.catalog.filter.apartments': 'Квартиры',
    'js.catalog.filter.rooms': 'Комнаты',
    'js.catalog.filter.sex': 'Подходит для',
    'js.catalog.filter.sex_any': 'Всех',
    'js.catalog.filter.sex_female': 'Девушек',
    'js.catalog.filter.sex_male': 'Парней',
    'js.catalog.filter.where': 'Где',
    'js.catalog.filter.select_in_the_scheme': 'Выбрать на схеме',
    'js.catalog.filter.area_on_the_map': 'Область на карте',
    'js.catalog.filter.in_the_scheme_or_by_name': 'На схеме или по названию',
    'js.catalog.filter.type_the_subway_name': 'Введите название станции',
    'js.catalog.filter.clear': 'Очистить',
    'js.catalog.filter.number_of_rooms': 'Количество комнат',
    'js.catalog.filter.distance_from_subway': 'Расстояние от метро',
    'js.catalog.filter.route_type_no_matter': 'Неважно',
    'js.catalog.filter.route_type_on_foot': 'Пешком',
    'js.catalog.filter.route_type_transport': 'Транспортом',
    'js.catalog.filter.minutes_short': 'мин',
    'js.catalog.filter.price': 'Цена',
    'js.catalog.filter.from': 'от',
    'js.catalog.filter.to': 'до',
    'js.catalog.filter.source': 'Источник',
    'js.catalog.filter.only_verified': 'Только проверенные',
    'js.catalog.filter.only_verified_tooltip':
      'Объявления из проверенных источников, одобренные модераторами',
    'js.catalog.filter.additionally': 'Дополнительно',
    'js.catalog.filter.space': 'Площадь в м\u003csup\u003e2\u003c/sup\u003e',
    'js.catalog.filter.floor': 'Этаж',
    'js.catalog.filter.facilities': 'Удобства',
    'js.catalog.filter.features': 'Особенности',
    'js.catalog.load_more': 'Загрузить еще',
    'js.catalog.map.placemark_hint.current_offer': 'актуальное предложение',
    'js.catalog.map.placemark_hint.viewed': 'просмотрено',
    'js.catalog.map.drawer.select_area_button': 'Обвести',
    'js.catalog.map.drawer.exit_button': 'Выйти',
    'js.catalog.map.drawer.clear_button': 'Очистить',
    'js.contacts_button.show_phone_number': 'Телефон',
    'js.contacts_button.owner_phone_number': 'Телефон',
    'js.contacts_button.leave_request': 'Оставить заявку',
    'js.contacts_modal.evaluate_call':
      'Оцените \u003cspan class="hidden-xs"\u003eзвонок\u003c/span\u003e',
    'js.contacts_modal.unverified_notice':
      'Осторожно, это объявление еще не\u0026nbsp;проверено, Локалс не\u0026nbsp;рекомендует самостоятельно связываться с\u0026nbsp;собственником, лучше поручите это \u003ca href="{url}"\u003eличному помощнику\u003c/a\u003e',
    'js.contacts_modal.other_owner_contacts':
      'Другие контакты собственника:{contacts}',
    'js.contacts_modal.label_not_for_agents': 'Агентам не звонить',
    'js.contacts_modal.note_about_prepayment':
      'Друзья, обратите внимание. Никогда, ни\u0026nbsp;при каких обстоятельствах \u003cspan class="marked"\u003eне\u0026nbsp;передавайте аванс за\u0026nbsp;квартиру до\u0026nbsp;тех пор, пока вы\u0026nbsp;не\u0026nbsp;посетите её\u0026nbsp;лично и\u0026nbsp;не\u0026nbsp;увидите документы,\u003c/span\u003e подтверждающие право собственности.',
    'js.contacts_modal.assistant_text':
      'Эта квартира сдается нашим проверенным партнером - приятным квалифицированным человеком, который ответит на все ваши вопросы и во всем вам поможет. Разумеется, никакой комиссии.',
    'js.contacts_modal.call_like_button': 'Все хорошо',
    'js.contacts_modal.call_dislike_button': 'Пожаловаться',
    'js.contacts_modal.call_dislike_dropdown.reason_not_relevant':
      'Объявление не актуально',
    'js.contacts_modal.call_dislike_dropdown.reason_do_not_answer':
      'Не берут трубку',
    'js.contacts_modal.call_dislike_dropdown.reason_false_photos':
      'Фотографии ложные',
    'js.contacts_modal.call_dislike_dropdown.reason_comission': 'Есть комиссия',
    'js.contacts_modal.call_dislike_dropdown.reason_another':
      'Другая причина (укажите)',
    'js.contacts_modal.call_dislike_form.reason_placeholder': 'Укажите причину',
    'js.contacts_modal.call_dislike_form.submit_button': 'Отправить',
    'js.control_favorite.remove_from_favorites': 'Убрать из избранного',
    'js.control_favorite.add_to_favorites': 'Добавить в избранное',
    'js.control_favorite.removing_from_favorites': 'Убираем из избранного...',
    'js.control_favorite.adding_to_favorites': 'Добавляем в избранное...',
    'js.control_favorite.auth_dropdown.you_need_to': 'Вам необходимо',
    'js.control_favorite.auth_dropdown.sign_in': 'войти',
    'js.control_favorite.auth_dropdown.or': 'или',
    'js.control_favorite.auth_dropdown.sign_up': 'зарегистрироваться',
    'js.control_promo.tooltip_ad_promoted': 'Это объявление продвигается',
    'js.control_promo.dropdown_title':
      'Это объявление продвигается. Хотите продвинуть своё?',
    'js.control_promo.dropdown_more_views':
      'В\u0026nbsp;10\u0026nbsp;раз больше просмотров',
    'js.control_promo.dropdown_always_on_top':
      'Всегда наверху в\u0026nbsp;поиске',
    'js.control_promo.dropdown_highlighted_in_list':
      'Выделено в\u0026nbsp;списке',
    'js.control_promo.dropdown_recommendation':
      'Включено в\u0026nbsp;рекомендации Locals',
    'js.control_promo.dropdown_order_button': 'Заказать',
    'js.favorite_button.favorited': 'В избранном',
    'js.favorite_button.to_favorites': 'В избранное',
    'js.favorite_button.wait': 'Подождите...',
    'js.metro_scheme_modal.apply_button': 'Применить',
    'js.metro_scheme_modal.clear_button': 'Очистить все станции',
    'js.plan_social_button.message':
      'Ищу квартиру в аренду без комиссии на Локалс. Советую! #locals_ru',
    'js.main_filters.title': 'Аренда квартир без\u0026nbsp;комиссии',
    'js.main_filters.in': 'в',
    'js.main_filters.any_subway': 'Любое метро',
    'js.main_filters.room': 'Комната',
    'js.main_filters.one_room_apartments': 'Однокомнатные квартиры',
    'js.main_filters.two_room_apartments': 'Двухкомнатные квартиры',
    'js.main_filters.three_room_apartments': 'Трехкомнатные квартиры',
    'js.main_filters.multi_room_apartments': 'Многокомнатные квартиры',
    'js.main_filters.submit_button': 'Найти объявления',
    'js.settings_modal.change_avatar': 'Сменить аватарку',
    'js.settings_modal.title_personal_information': 'Личная информация',
    'js.settings_modal.title_change_password': 'Сменить пароль',
    'js.settings_modal.title_phone': 'Телефон',
    'js.settings_modal.title_phone_bind': 'Привязать телефон',
    'js.settings_modal.title_social_accounts': 'Привязать учетную запись',
    'js.settings_modal.name_placeholder': 'Имя',
    'js.settings_modal.email_placeholder': 'Электронная почта',
    'js.settings_modal.new_password_placeholder': 'Новый пароль',
    'js.settings_modal.confirm_password_placeholder': 'Подтверждение пароля',
    'js.settings_modal.phone_placeholder': 'Номер телефона',
    'js.settings_modal.sms_code_placeholder': 'SMS пароль',
    'js.settings_modal.sms_code_success_note':
      'Мы\u0026nbsp;отправили на\u0026nbsp;ваш номер SMS-код',
    'js.settings_modal.sms_code_timer_note':
      'Изменить номер телефона или запросить SMS-код повторно можно будет через {time}',
    'js.settings_modal.sms_code_timer_note_seconds.one': 'секунду',
    'js.settings_modal.sms_code_timer_note_seconds.few': 'секунды',
    'js.settings_modal.sms_code_timer_note_seconds.many': 'секунд',
    'js.settings_modal.sms_code_timer_note_seconds.other': 'секунд',
    'js.settings_modal.resend_sms_code_link': 'Отправить SMS-код повторно',
    'js.settings_modal.get_sms_code_button': 'Получить SMS-код',
    'js.settings_modal.change_button': 'Изменить',
    'js.settings_modal.save_button': 'Сохранить',
    'js.settings_modal.contact_us': 'Вопросы? Пишите на {email}',
    'js.settings_modal.validation.password_minlength':
      'Пароль должен быть не менее 1 символа',
    'js.settings_modal.validation.password_equal_to': 'Пароли должны совпадать',
    'js.settings_modal.validation.password_confirmation_required':
      'Введите пароль повторно',
    'js.settings_modal.validation.password_confirmation_minlength':
      'Пароль должен быть не менее 1 символа',
    'js.settings_modal.validation.phone_wrong_format':
      'Неверный формат телефона',
    'js.settings_modal.validation.field_wrong_format':
      'Поле имеет неправильный формат',
    'js.social_button.vkontakte': 'Вконтакте',
    'js.social_button.facebook': 'Facebook',
    'js.flash_notifier.could_not_load_image':
      'Не удалось загрузить изображение',
    'js.flash_notifier.error_has_occurred_try_again':
      'Произошла ошибка! Попробуйте еще раз.',
    'js.flash_notifier.error_has_occurred_refresh_browser':
      'Произошла ошибка! Попробуйте обновить страницу.',
    'js.flash_notifier.error_has_occurred_check_connection':
      'Произошла ошибка! Проверьте соединение с интернетом.',
    'js.flash_notifier.could_not_load_all_ads_try_again':
      'Не удалось загрузить все объявления! Попробуйте ещё раз.',
    'js.flash_notifier.session_is_outdated':
      'Сессия устарела! Попробуйте еще раз восстановить пароль.',
    'js.flash_notifier.something_went_wrong':
      'Что-то пошло не так! Попробуйте чуть позже еще раз. Спасибо',
    'js.flash_notifier.thanks_for_mark':
      'Спасибо за Вашу оценку! Вы помогаете нам сделать сервис лучше',
    'js.flash_notifier.added_to_favorites':
      'Добавлено в \u003ca href="{link}" title="избранное"\u003eизбранное\u003c/a\u003e',
    'js.flash_notifier.there_are_no_panoramas': 'В заданной точке нет панорам.',
    'js.ad_form.extra_option.living_size': 'Жилая площадь',
    'js.ad_form.extra_option.kitchen_size': 'Кухня',
    'js.ad_form.extra_option.apt_number': 'Номер квартиры',
    'js.ad_form.extra_option.build_year': 'Год постройки',
    'js.ad_form.extra_option.ceiling_height': 'Высота потолков',
    'js.ad_form.extra_option.building_type': 'Тип дома',
    'js.ad_form.extra_option.building_types.brick': 'Кирпичный',
    'js.ad_form.extra_option.building_types.monolithic': 'Монолитный',
    'js.ad_form.extra_option.building_types.panel': 'Панельный',
    'js.ad_form.extra_option.building_types.blocky': 'Блочный',
    'js.ad_form.extra_option.building_types.wood': 'Деревянный',
    'js.ad_form.extra_option.building_types.stalinist': 'Сталинский',
    'js.ad_form.extra_option.building_types.monolithic_brick':
      'Монолитно-кирпичный',
    'js.ad_form.extra_option.propetry_type': 'Тип недвижимости',
    'js.ad_form.extra_option.cadastral_number': 'Кадастровый номер',
    'js.ad_form.step': 'Шаг',
    'js.ad_form.or': 'или',
    'js.ad_form.errors.email': 'Email имеет неправильный формат',
    'js.ad_form.errors.field_required': 'Поле обязательно к заполнению',
    'js.ad_form.errors.select_required': 'Необходимо что-то выбрать',
    'js.ad_form.errors.image_required': 'Загрузите хотя бы 1 изображение',
    'js.ad_form.errors.must_be_numeric':
      'Следующие поля должны быть числовыми: Площадь, Этаж, Этажей в доме',
    'js.ad_form.errors.greater_than': 'Введите число больше 0',
    'js.ad_form.errors.phone_wrong_format': 'Неверный формат телефона',
    'js.ad_form.city.label': 'Город',
    'js.ad_form.auth.label': 'Вход или регистрация',
    'js.ad_form.auth.footnotes_register': 'Регистрировались?',
    'js.ad_form.auth.footnotes_sign': 'Войдите',
    'js.ad_form.kind.label': 'Что вы сдаете?',
    'js.ad_form.kind.label_sale': 'Что вы продаете?',
    'js.ad_form.kind.rent_room': 'Комнату',
    'js.ad_form.kind.rent_apartment': 'Квартиру',
    'js.ad_form.kind.type': 'Выберите тип объявления',
    'js.ad_form.kind.agency': 'Кто вы?',
    'js.ad_form.kind.commission': 'Комиссия?',
    'js.ad_form.address.label': 'Введите адрес',
    'js.ad_form.address.placeholder': 'Например, Профсоюзная, 10',
    'js.ad_form.address.footnote':
      'Пожалуйста, убедитесь, что адрес отображается на карте',
    'js.ad_form.subways.label': 'Ближайшее метро',
    'js.ad_form.district.label': 'Выберите район',
    'js.ad_form.title.title': 'Заголовок',
    'js.ad_form.title.placeholder': 'Например: «Трёшка на Патриках».',
    'js.ad_form.description.label': 'Описание',
    'js.ad_form.description.placeholder':
      'Подробное описание – это обязательный параметр объявления. Если не знаете что писать, воспользуйтесь подсказкой на зеленом фоне',
    'js.ad_form.description.footnote':
      '\u003ch5\u003eОписывайте по пунктам:\u003c/h5\u003e\u003cul\u003e\u003cli\u003eДом и квартира (чистый подъезд, высокие потолки)\u003c/li\u003e\u003cli\u003eМебель, бытовая техника, интернет\u003c/li\u003e\u003cli\u003eИнфраструктура: все, что делает жизнь удобной (магазины, поликлиники, школы)\u003c/li\u003e\u003cli\u003eТранспорт и парковка (расстояние до метро/остановки, есть ли парковка у дома)\u003c/li\u003e\u003cli\u003eСоседи и принятый в квартире образ жизни (если сдаете комнату)\u003c/li\u003e\u003c/ul\u003e',
    'js.ad_form.images.label': 'Фотографии',
    'js.ad_form.images.cover': 'Обложка',
    'js.ad_form.images.select_text':
      'Выберите файл на своём компьютере или\u0026nbsp;просто переместите сюда фотографии (потяните за\u0026nbsp;фото, чтобы поменять порядок)',
    'js.ad_form.images.footnote':
      '\u003ch5\u003eТребования к фотографиям:\u003c/h5\u003e\u003cul\u003e\u003cli\u003eОтсутствие на них знаков других сайтов (авито, циан, из рук в руки)\u003c/li\u003e\u003cli\u003eНаличие на них квартиры или комнаты\u003c/li\u003e\u003cli\u003eПриемлемый размер (не менее 800px)\u003c/li\u003e\u003cli\u003eНесколько четких фотографий на которых хорошо видно комнаты, кухню, ванную и тд\u003c/li\u003e\u003c/ul\u003e',
    'js.ad_form.images.select_button': 'Выбрать фотографии',
    'js.ad_form.images.actions.remove': 'Удалить',
    'js.ad_form.images.actions.rotate': 'Повернуть',
    'js.ad_form.sex.label': 'Для кого',
    'js.ad_form.sex.any': 'Не важно',
    'js.ad_form.sex.female': 'Только для девушек',
    'js.ad_form.sex.male': 'Только для парней',
    'js.ad_form.rooms.label': 'Количество комнат',
    'js.ad_form.options.label': 'Параметры',
    'js.ad_form.options.space': 'Площадь',
    'js.ad_form.options.sq': 'м\u003csup\u003e2\u003c/sup\u003e',
    'js.ad_form.options.floor': 'Этаж',
    'js.ad_form.options.floors_amount': 'Этажей в доме',
    'js.ad_form.price.label': 'Цена',
    'js.ad_form.ext.label':
      'Дополнительные параметры (заполнять не обязательно)',
    'js.ad_form.ext.amenities': 'Удобства',
    'js.ad_form.ext.features': 'Особенности',
    'js.ad_form.phone.label': 'Телефон',
    'js.ad_form.phone.footnote':
      'Наши модераторы позвонят Вам для проверки квартиры. Пожалуйста, будьте на связи.',
    'js.ad_form.phone.protect_title': 'Новинка!',
    'js.ad_form.phone.protect_action': 'Защитите свой мобильный',
    'js.ad_form.phone.protect_buy': 'Подключить за',
    'js.ad_form.phone.protect_note':
      'Мы включим временный телефонный номер с переадресацией на ваш мобильный, который позволит вам скрыть свой реальный номер. Когда вы сдадите квартиру, этот временный номер отключится. Таким образом вы сможете защитить свой номер от нежелательных звонков и попадания в базу к риэлторам.',
    'js.ad_form.phone.protect_benefits.first':
      'Специальный номер с\u0026nbsp;переадресацией на\u0026nbsp;ваш телефон',
    'js.ad_form.phone.protect_benefits.second':
      'Настоящий номер скрыт, он\u0026nbsp;не\u0026nbsp;попадет в\u0026nbsp;базы к\u0026nbsp;риэлторам',
    'js.ad_form.phone.protect_benefits.third':
      'Фильтруем до\u0026nbsp;95% нежелательных звонков!',
    'js.ad_form.other_contacts.label':
      'Еще контакты (не\u0026nbsp;обязательно)',
    'js.ad_form.other_contacts.footnote':
      'Вы можете оставить свой емейл, ссылки на социальные сети или любой другой предпочтительный способ',
    'js.ad_form.other_contacts.placeholder':
      'Например: пишите мне на фейсбук facebook.com/myname, после чего созвонимся и договоримся',
    'js.ad_form.plan.label': 'Выберите тариф',
    'js.ad_form.plan.fast.title': 'Сдайте быстрее',
    'js.ad_form.plan.fast.desc':
      'В\u0026nbsp;10\u0026nbsp;раз больше просмотров',
    'js.ad_form.plan.fast.text1': 'Выделено в\u0026nbsp;списке',
    'js.ad_form.plan.fast.text2':
      'Всегда наверху в\u0026nbsp;результатах поиска',
    'js.ad_form.plan.fast.text3':
      '\u003cb class="color-green"\u003eБонус\u003c/b\u003e: модерация за\u0026nbsp;1\u0026nbsp;час(в\u0026nbsp;рабочие дни с\u0026nbsp;11\u0026nbsp;до\u0026nbsp;19)',
    'js.ad_form.plan.fast.access_common': 'Сразу доступно всем посетителям',
    'js.ad_form.plan.fast.access_paid': 'Видно на главной странице',
    'js.ad_form.plan.normal.title': 'Обычное объявление',
    'js.ad_form.plan.normal.access_paid':
      'Доступно всем посетителям через {period} (а\u0026nbsp;сначала\u0026nbsp;— только подписчикам)',
    'js.ad_form.plan.normal.access_common': 'Доступно в общем поиске',
    'js.ad_form.plan.normal.checking': 'Проверяется в\u0026nbsp;общей очереди',
    'js.ad_form.plan.normal.free': 'Бесплатно',
    'js.ad_form.plan.buy': 'Купить',
    'js.ad_form.plan.for': 'за',
    'js.ad_form.actions.save': 'Сохранить',
    'js.ad_form.actions.pend': 'Отправить на модерацию',
    'js.ad_form.editing_note':
      'При редактировании заголовка, описания, номера телефона или фотографий, объявление будет снова отправлено на модерацию',
    'js.offer_gallery.photo': 'фото',
    'js.offer_map.view.map': 'Карта',
    'js.offer_map.view.street': 'Вид с улицы',
    'js.offer_featuring_form.actions.submit': 'Продолжить',
    'js.react_select.no_results': 'Ничего не найдено',
    'js.search_assistance_email_form.text': 'Общий текст',
    'js.search_assistance_email_form.link': 'Ссылка',
    'js.search_assistance_email_form.comment': 'Комментарий',
    'js.search_assistance_email_form.remove': 'Удалить',
    'js.search_assistance_email_form.add': 'Добавить',
    'js.search_assistance_email_form.send': 'Отправить',
    'js.search_assistance_email_form.preview': 'Предпросмотр',
    'js.search_assistance_email_form.close': 'Закрыть',
    'js.search_assistance_email_form.success_alert':
      'Письмо успешно отправлено',
    'js.search_assistance_form.city_id': 'Город',
    'js.search_assistance_form.search_assistant_id': 'Помощник',
    'js.search_assistance_form.lead_id': 'Заявка',
    'js.search_assistance_form.user_id': 'Пользователь',
    'js.search_assistance_form.name': 'Имя',
    'js.search_assistance_form.phone': 'Телефон',
    'js.search_assistance_form.email': 'Емайл',
    'js.search_assistance_form.address': 'Емайл',
    'js.search_assistance_form.comment': 'Комментарий',
    'js.search_assistance_form.rooms_amount': 'Тип',
    'js.search_assistance_form.price_min': 'Цена мин',
    'js.search_assistance_form.price_max': 'Цена макс',
    'js.search_assistance_form.client_fee_max': 'Комиссия макс (%)',
    'js.search_assistance_form.space_min': 'Площадь мин',
    'js.search_assistance_form.space_max': 'Площадь макс',
    'js.search_assistance_form.floor_min': 'Этаж мин',
    'js.search_assistance_form.floor_max': 'Этаж макс',
    'js.search_assistance_form.subject_ids': 'Удобства',
    'js.search_assistance_form.tag_ids': 'Особенности',
    'js.search_assistance_form.amenity_ids': 'Удобства/Особенности',
    'js.search_assistance_form.publishing_period': 'Срок жизни объявления',
    'js.search_assistance_form.expires_at': 'Дата окончания заявки',
    'js.search_assistance_form.subway_path_mode': 'Способ добираться до метро',
    'js.search_assistance_form.path_duration': 'Время до метро в минутах',
    'js.search_assistance_form.remove': 'Удалить',
    'js.search_assistance_form.add': 'Добавить',
    'js.search_assistance_form.repairs': 'Ремонт',
    'js.search_assistance_form.animals': 'Животные',
    'js.search_assistance_form.residents': 'Кто будет проживать в квартире',
    'js.search_assistance_form.resident_work': 'Место работы',
    'js.search_assistance_form.urgency_date': 'Желаемая дата заселения',
    'js.search_assistance_form.urgency_type': 'Как срочно нужно заселиться',
    'js.search_assistance_form.from_homeowner': 'Предложения от',
    'js.search_assistance_form.custom_location': 'Расположение',
    'js.search_assistance_form.priorities': 'Приоритеты',
    'js.search_assistance_form.receive_type': 'Предпочитаемый способ связи',
    'js.search_assistance_form.show_map': 'Показать карту',
    'js.search_assistance_form.close_map': 'Закрыть карту',
    'js.search_assistance_form.save': 'Сохранить',
    'js.buyer_price.open_button': 'Предложить свою цену',
    'js.buyer_price.title': 'Предложите свою цену',
    'js.buyer_price.phone_placeholder': 'Ваш номер телефона',
    'js.buyer_price.validation.phone_wrong_format': 'Неверный формат телефона',
    'js.payment_form.new_card': 'Новая карта',
    'js.payment_form.linked_cards': 'Привязанные карты',
    'js.payment_form.remove_card': 'удалить карту',
    'js.payment_form.add_new_card': 'добавить новую карту',
    'js.payment_form.submit_button': 'Оплатить',
    'js.payment_form.or': 'или',
    'js.payment_form.security_text':
      'Ваши платежные данные защищены технологией PCI DSS',
    'js.payment_form.confirm_remove_card':
      'Вы уверены, что хотите удалить карточку?',
    'js.payment_form.flash_notifier.card_successfully_deleted':
      'Карточка была успешно удалена',
    'js.payment_card.number': 'Номер карты',
    'js.payment_card.expiry': 'Срок действия',
    'js.payment_card.cvc': 'СVC/CVV',
    'js.payment_card.remember_card':
      'Запомнить \u003cspan class="hidden-xs"\u003eкарту\u003c/span\u003e',
    'js.payment_card.placeholders.expiry': 'ММ/ГГ',
    'js.payment_card.errors.number': 'Неверный номер карты',
    'js.payment_card.errors.expiry': 'Неверный срок действия',
    'js.payment_card.tooltips.cvc': 'Три цифры на обратной стороне карты',
    'js.payment_card.tooltips.remember':
      'Сохранить карту для следующих покупок',
  },
  ru: {
    'js.currencies.UAH': 'грн',
    'js.currencies.RUB': 'руб',
    'js.currencies.USD': 'usd',
    'js.currencies.EUR': 'eur',
    'js.plurals.days.one': 'день',
    'js.plurals.days.few': 'дня',
    'js.plurals.days.many': 'дней',
    'js.plurals.days.other': 'дней',
    'js.plurals.ratings.one': 'оценка',
    'js.plurals.ratings.few': 'оценки',
    'js.plurals.ratings.many': 'оценок',
    'js.plurals.ratings.other': 'оценок',
    'js.plurals.apartments.one': 'квартира',
    'js.plurals.apartments.few': 'квартиры',
    'js.plurals.apartments.many': 'квартир',
    'js.plurals.apartments.other': 'квартир',
    'js.plurals.apartments_sorted.one': 'квартира отсортированы',
    'js.plurals.apartments_sorted.few': 'квартиры отсортированы',
    'js.plurals.apartments_sorted.many': 'квартир отсортированы',
    'js.plurals.apartments_sorted.other': 'квартир отсортированы',
    'js.plurals.ads.one': 'объявление',
    'js.plurals.ads.few': 'объявления',
    'js.plurals.ads.many': 'объявлений',
    'js.plurals.ads.other': 'объявлений',
    'js.ad_card.status.viewed': 'Просмотрено',
    'js.ad_card.meter_short': 'м',
    'js.ad_card.month_short': 'мес',
    'js.ad_card.space_for_rent.one_room_apartments': '1-комн.',
    'js.ad_card.space_for_rent.two_room_apartments': '2-комн.',
    'js.ad_card.space_for_rent.three_room_apartments': '3-комн.',
    'js.ad_card.space_for_rent.multi_room_apartments': '4+',
    'js.ad_card.space_for_rent.room': 'К',
    'js.ad_card.space_for_rent_on_map.one_room_apartments': '1-комн.',
    'js.ad_card.space_for_rent_on_map.two_room_apartments': '2-комн.',
    'js.ad_card.space_for_rent_on_map.three_room_apartments': '3-комн.',
    'js.ad_card.space_for_rent_on_map.multi_room_apartments': '4+',
    'js.ad_card.space_for_rent_on_map.room': 'комната',
    'js.ad_newsletter_modal.new_offers_by_email':
      'Получайте новые предложения по\u0026nbsp;почте',
    'js.ad_newsletter_modal.note_for_newsletter':
      'При наличии обновлений по вашему запросу, мы будем один раз в день отправлять новинки вам на почту в течение месяца. Отписаться можно в личном кабинете в разделе',
    'js.ad_newsletter_modal.note_for_newsletter_link': 'уведомления',
    'js.ad_newsletter_modal.select_more_filters':
      'Выберите больше фильтров, по вашим параметрам слишком много объявлений',
    'js.ad_newsletter_modal.more_subways_button.show.counting.one':
      'и ещё {count} станцию',
    'js.ad_newsletter_modal.more_subways_button.show.counting.few':
      'и ещё {count} станции',
    'js.ad_newsletter_modal.more_subways_button.show.counting.many':
      'и ещё {count} станций',
    'js.ad_newsletter_modal.more_subways_button.hide.counting.one':
      'свернуть станцию',
    'js.ad_newsletter_modal.more_subways_button.hide.counting.few':
      'свернуть станции',
    'js.ad_newsletter_modal.more_subways_button.hide.counting.many':
      'свернуть станции',
    'js.ad_newsletter_modal.you_choosed': 'Вы выбрали',
    'js.ad_newsletter_modal.email_placeholder': 'Email',
    'js.ad_newsletter_modal.subscribe_button': 'Подписаться',
    'js.ad_newsletter_modal.success_title': 'Подписка оформлена!',
    'js.ad_newsletter_modal.success_text':
      'Теперь новые объявления по заданным критериям будут приходить вам на почту.',
    'js.ad_newsletter_modal.success_text_subscription_status':
      'Удалить или проверить статус подписки можно в разделе {section} в личном кабинете.',
    'js.ad_newsletter_modal.success_text_subscription_status_section':
      'уведомления',
    'js.after_create_modal.ready': 'Готово!',
    'js.after_create_modal.sent_for_moderation':
      'Объявление отправлено на\u0026nbsp;модерацию, скоро вам позвонит наш модератор.',
    'js.after_create_modal.time_contact':
      'Если вы\u0026nbsp;разместили объявление с\u0026nbsp;10\u0026nbsp;до\u0026nbsp;19\u0026nbsp;часов в\u0026nbsp;рабочие дни, мы\u0026nbsp;позвоним в\u0026nbsp;течение 1-2\u0026nbsp;часов.',
    'js.after_create_modal.ad_changing':
      'Если вы\u0026nbsp;вносили исправления в\u0026nbsp;объявление и\u0026nbsp;они не\u0026nbsp;требуют дополнительной проверки, мы\u0026nbsp;разместим его на\u0026nbsp;сайте без звонка модератора.',
    'js.after_create_modal.publication_notice':
      'После проверки вашего объявления мы\u0026nbsp;сразу вышлем емейл с\u0026nbsp;результатами: опубликовано или отклонено.',
    'js.after_create_modal.contact_us':
      'Вам не\u0026nbsp;позвонили в\u0026nbsp;течение 24\u0026nbsp;часов после публикации? Напишите нам на\u0026nbsp;{email}',
    'js.app_banner.title': 'А\u0026nbsp;в\u0026nbsp;приложении ещё удобнее',
    'js.app_banner.button': 'Скачать',
    'js.auth_modal.login_title': 'Вход',
    'js.auth_modal.login_title_text': 'Вы здесь впервые? Сначала',
    'js.auth_modal.login_title_link': 'зарегистрируйтесь',
    'js.auth_modal.login_fast_social_auth':
      'Быстрый вход через социальные сети',
    'js.auth_modal.register_title': 'Регистрация',
    'js.auth_modal.register_title_text': 'Вы уже тут были?',
    'js.auth_modal.register_title_link': 'Тогда войдите',
    'js.auth_modal.register_fast_social_auth':
      'Быстрая регистрация через социальные сети',
    'js.auth_modal.register_success_title': 'Спасибо за\u0026nbsp;регистрацию',
    'js.auth_modal.register_success_text':
      'Теперь вы\u0026nbsp;можете размещать объявления и\u0026nbsp;покупать доступ к\u0026nbsp;контактам',
    'js.auth_modal.recovery_title': 'Восстановление пароля',
    'js.auth_modal.recovery_title_link': 'Вернуться',
    'js.auth_modal.recovery_success_title': 'Инструкции отправлены',
    'js.auth_modal.recovery_success_text':
      'Мы\u0026nbsp;отправили новый пароль на\u0026nbsp;вашу электронную почту.\u003cbr /\u003e Если вы\u0026nbsp;не\u0026nbsp;получили пароль, попробуйте отправить',
    'js.auth_modal.recovery_success_text_link': 'еще раз',
    'js.auth_modal.reset_title': 'Восстановление пароля',
    'js.auth_modal.reset_title_link': 'Вход',
    'js.auth_modal.reset_success_title': 'Поздравляем!',
    'js.auth_modal.reset_success_text':
      'Ваш пароль был успешно изменён.\u003cbr /\u003e Сейчас вы будете автоматически авторизированы.',
    'js.auth_modal.email_placeholder': 'Электронная почта',
    'js.auth_modal.phone_placeholder': 'Номер телефона',
    'js.auth_modal.password_placeholder': 'Пароль',
    'js.auth_modal.new_password_placeholder': 'Новый пароль',
    'js.auth_modal.confirm_password_placeholder': 'Подтверждение пароля',
    'js.auth_modal.sms_code_placeholder': 'SMS пароль',
    'js.auth_modal.by_email_link': 'По емейлу',
    'js.auth_modal.by_phone_link': 'По телефону',
    'js.auth_modal.validation.field_required': 'Это поле обязательное',
    'js.auth_modal.validation.field_wrong_format':
      'Поле имеет неправильный формат',
    'js.auth_modal.validation.password_minlength':
      'Пароль должен быть не менее 1 символа',
    'js.auth_modal.validation.password_equal_to': 'Пароли должны совпадать',
    'js.auth_modal.accept_terms_of_use': 'Регистрируясь, вы принимаете условия',
    'js.auth_modal.accept_terms_of_use_link': 'пользовательского соглашения',
    'js.auth_modal.accept_terms_of_use_and_agree': 'и соглашаетесь на',
    'js.auth_modal.accept_terms_of_use_personal_data_link':
      'обработку ваших персональных данных',
    'js.auth_modal.do_not_remember_password':
      'Не помните пароль? Не беда – давайте',
    'js.auth_modal.do_not_remember_password_link': 'восстановим',
    'js.auth_modal.sms_code_success_note':
      'Мы\u0026nbsp;отправили на\u0026nbsp;ваш номер SMS-код',
    'js.auth_modal.sms_code_timer_note':
      'Запросить SMS-код повторно можно будет через {time}',
    'js.auth_modal.sms_code_timer_note_seconds.one': 'секунду',
    'js.auth_modal.sms_code_timer_note_seconds.few': 'секунды',
    'js.auth_modal.sms_code_timer_note_seconds.many': 'секунд',
    'js.auth_modal.sms_code_timer_note_seconds.other': 'секунд',
    'js.auth_modal.or': 'или',
    'js.auth_modal.or_phone_link': 'или телефон',
    'js.auth_modal.or_email_link': 'или почта',
    'js.auth_modal.sign_in_button': 'Войти',
    'js.auth_modal.sign_up_button': 'Зарегистрироваться',
    'js.auth_modal.send_password_button': 'Отправить пароль',
    'js.auth_modal.change_password_button': 'Изменить пароль',
    'js.auth_modal.get_sms_code_button': 'Получить SMS-код',
    'js.catalog.breadcrumbs.home': 'Главная',
    'js.catalog.breadcrumbs.search': 'Поиск',
    'js.catalog.sorting.ads': 'объявлений',
    'js.catalog.sorting.apartments': 'квартиры отсортированы',
    'js.catalog.sorting.apartments_short': 'квартир',
    'js.catalog.sorting.by_date': 'по дате добавления',
    'js.catalog.sorting.by_price': 'по цене',
    'js.catalog.sorting.by_area': 'по площади',
    'js.catalog.view.list': 'Списком',
    'js.catalog.view.map': 'На карте',
    'js.catalog.closed_access_card.title': 'Не пропустите свой вариант',
    'js.catalog.closed_access_card.text':
      'Личный помощник видит объявления еще до того, как они попадут на сайт и сразу предлагает их вам',
    'js.catalog.closed_access_card.request': 'Подробнее',
    'js.catalog.subscription_card.title': 'Рассылка с новыми объявлениями',
    'js.catalog.subscription_card.text':
      'Настройте фильтр и\u0026nbsp;получайте уведомления о\u0026nbsp;подходящих вам квартирах ',
    'js.catalog.subscription_card.request': 'Подписаться',
    'js.catalog.whatsapp_help_card.title': 'Консультация в WhatsApp',
    'js.catalog.whatsapp_help_card.text':
      'Расскажем о сервисе и\u0026nbsp;поможем подобрать подходящий именно вам тариф',
    'js.catalog.whatsapp_help_card.request': 'Перейти в WhatsApp',
    'js.catalog.search_assistant_card.title': 'Надоело искать?',
    'js.catalog.search_assistant_card.text':
      'Укажите требования к\u0026nbsp;квартире и поручите поиск личному\u0026nbsp;помощнику',
    'js.catalog.search_assistant_card.request': 'Оставить заявку',
    'js.catalog.filter.title': 'Фильтр',
    'js.catalog.filter.reset': 'Сбросить',
    'js.catalog.filter.apply': 'Применить',
    'js.catalog.filter.rent': 'Аренда длительно',
    'js.catalog.filter.daily_rent': 'Аренда посуточно',
    'js.catalog.filter.sale': 'Продажа',
    'js.catalog.filter.what': 'Что',
    'js.catalog.filter.apartments': 'Квартиры',
    'js.catalog.filter.rooms': 'Комнаты',
    'js.catalog.filter.sex': 'Подходит для',
    'js.catalog.filter.sex_any': 'Всех',
    'js.catalog.filter.sex_female': 'Девушек',
    'js.catalog.filter.sex_male': 'Парней',
    'js.catalog.filter.where': 'Где',
    'js.catalog.filter.select_in_the_scheme': 'Выбрать на схеме',
    'js.catalog.filter.area_on_the_map': 'Область на карте',
    'js.catalog.filter.in_the_scheme_or_by_name': 'На схеме или по названию',
    'js.catalog.filter.type_the_subway_name': 'Введите название станции',
    'js.catalog.filter.clear': 'Очистить',
    'js.catalog.filter.number_of_rooms': 'Количество комнат',
    'js.catalog.filter.distance_from_subway': 'Расстояние от метро',
    'js.catalog.filter.route_type_no_matter': 'Неважно',
    'js.catalog.filter.route_type_on_foot': 'Пешком',
    'js.catalog.filter.route_type_transport': 'Транспортом',
    'js.catalog.filter.minutes_short': 'мин',
    'js.catalog.filter.price': 'Цена',
    'js.catalog.filter.from': 'от',
    'js.catalog.filter.to': 'до',
    'js.catalog.filter.source': 'Источник',
    'js.catalog.filter.only_verified': 'Только проверенные',
    'js.catalog.filter.only_verified_tooltip':
      'Объявления из проверенных источников, одобренные модераторами',
    'js.catalog.filter.additionally': 'Дополнительно',
    'js.catalog.filter.space': 'Площадь в м\u003csup\u003e2\u003c/sup\u003e',
    'js.catalog.filter.floor': 'Этаж',
    'js.catalog.filter.facilities': 'Удобства',
    'js.catalog.filter.features': 'Особенности',
    'js.catalog.load_more': 'Загрузить еще',
    'js.catalog.map.placemark_hint.current_offer': 'актуальное предложение',
    'js.catalog.map.placemark_hint.viewed': 'просмотрено',
    'js.catalog.map.drawer.select_area_button': 'Обвести',
    'js.catalog.map.drawer.exit_button': 'Выйти',
    'js.catalog.map.drawer.clear_button': 'Очистить',
    'js.contacts_button.show_phone_number': 'Телефон',
    'js.contacts_button.owner_phone_number': 'Телефон',
    'js.contacts_button.leave_request': 'Оставить заявку',
    'js.contacts_modal.evaluate_call':
      'Оцените \u003cspan class="hidden-xs"\u003eзвонок\u003c/span\u003e',
    'js.contacts_modal.unverified_notice':
      'Осторожно, это объявление еще не\u0026nbsp;проверено, Локалс не\u0026nbsp;рекомендует самостоятельно связываться с\u0026nbsp;собственником, лучше поручите это \u003ca href="{url}"\u003eличному помощнику\u003c/a\u003e',
    'js.contacts_modal.other_owner_contacts':
      'Другие контакты собственника:{contacts}',
    'js.contacts_modal.label_not_for_agents': 'Агентам не звонить',
    'js.contacts_modal.note_about_prepayment':
      'Друзья, обратите внимание. Никогда, ни\u0026nbsp;при каких обстоятельствах \u003cspan class="marked"\u003eне\u0026nbsp;передавайте аванс за\u0026nbsp;квартиру до\u0026nbsp;тех пор, пока вы\u0026nbsp;не\u0026nbsp;посетите её\u0026nbsp;лично и\u0026nbsp;не\u0026nbsp;увидите документы,\u003c/span\u003e подтверждающие право собственности.',
    'js.contacts_modal.assistant_text':
      'Эта квартира сдается нашим проверенным партнером - приятным квалифицированным человеком, который ответит на все ваши вопросы и во всем вам поможет. Разумеется, никакой комиссии.',
    'js.contacts_modal.call_like_button': 'Все хорошо',
    'js.contacts_modal.call_dislike_button': 'Пожаловаться',
    'js.contacts_modal.call_dislike_dropdown.reason_not_relevant':
      'Объявление не актуально',
    'js.contacts_modal.call_dislike_dropdown.reason_do_not_answer':
      'Не берут трубку',
    'js.contacts_modal.call_dislike_dropdown.reason_false_photos':
      'Фотографии ложные',
    'js.contacts_modal.call_dislike_dropdown.reason_comission': 'Есть комиссия',
    'js.contacts_modal.call_dislike_dropdown.reason_another':
      'Другая причина (укажите)',
    'js.contacts_modal.call_dislike_form.reason_placeholder': 'Укажите причину',
    'js.contacts_modal.call_dislike_form.submit_button': 'Отправить',
    'js.control_favorite.remove_from_favorites': 'Убрать из избранного',
    'js.control_favorite.add_to_favorites': 'Добавить в избранное',
    'js.control_favorite.removing_from_favorites': 'Убираем из избранного...',
    'js.control_favorite.adding_to_favorites': 'Добавляем в избранное...',
    'js.control_favorite.auth_dropdown.you_need_to': 'Вам необходимо',
    'js.control_favorite.auth_dropdown.sign_in': 'войти',
    'js.control_favorite.auth_dropdown.or': 'или',
    'js.control_favorite.auth_dropdown.sign_up': 'зарегистрироваться',
    'js.control_promo.tooltip_ad_promoted': 'Это объявление продвигается',
    'js.control_promo.dropdown_title':
      'Это объявление продвигается. Хотите продвинуть своё?',
    'js.control_promo.dropdown_more_views':
      'В\u0026nbsp;10\u0026nbsp;раз больше просмотров',
    'js.control_promo.dropdown_always_on_top':
      'Всегда наверху в\u0026nbsp;поиске',
    'js.control_promo.dropdown_highlighted_in_list':
      'Выделено в\u0026nbsp;списке',
    'js.control_promo.dropdown_recommendation':
      'Включено в\u0026nbsp;рекомендации Locals',
    'js.control_promo.dropdown_order_button': 'Заказать',
    'js.favorite_button.favorited': 'В избранном',
    'js.favorite_button.to_favorites': 'В избранное',
    'js.favorite_button.wait': 'Подождите...',
    'js.metro_scheme_modal.apply_button': 'Применить',
    'js.metro_scheme_modal.clear_button': 'Очистить все станции',
    'js.plan_social_button.message':
      'Ищу квартиру в аренду без комиссии на Локалс. Советую! #locals_ru',
    'js.main_filters.title': 'Аренда квартир без\u0026nbsp;комиссии',
    'js.main_filters.in': 'в',
    'js.main_filters.any_subway': 'Любое метро',
    'js.main_filters.room': 'Комната',
    'js.main_filters.one_room_apartments': 'Однокомнатные квартиры',
    'js.main_filters.two_room_apartments': 'Двухкомнатные квартиры',
    'js.main_filters.three_room_apartments': 'Трехкомнатные квартиры',
    'js.main_filters.multi_room_apartments': 'Многокомнатные квартиры',
    'js.main_filters.submit_button': 'Найти объявления',
    'js.settings_modal.change_avatar': 'Сменить аватарку',
    'js.settings_modal.title_personal_information': 'Личная информация',
    'js.settings_modal.title_change_password': 'Сменить пароль',
    'js.settings_modal.title_phone': 'Телефон',
    'js.settings_modal.title_phone_bind': 'Привязать телефон',
    'js.settings_modal.title_social_accounts': 'Привязать учетную запись',
    'js.settings_modal.name_placeholder': 'Имя',
    'js.settings_modal.email_placeholder': 'Электронная почта',
    'js.settings_modal.new_password_placeholder': 'Новый пароль',
    'js.settings_modal.confirm_password_placeholder': 'Подтверждение пароля',
    'js.settings_modal.phone_placeholder': 'Номер телефона',
    'js.settings_modal.sms_code_placeholder': 'SMS пароль',
    'js.settings_modal.sms_code_success_note':
      'Мы\u0026nbsp;отправили на\u0026nbsp;ваш номер SMS-код',
    'js.settings_modal.sms_code_timer_note':
      'Изменить номер телефона или запросить SMS-код повторно можно будет через {time}',
    'js.settings_modal.sms_code_timer_note_seconds.one': 'секунду',
    'js.settings_modal.sms_code_timer_note_seconds.few': 'секунды',
    'js.settings_modal.sms_code_timer_note_seconds.many': 'секунд',
    'js.settings_modal.sms_code_timer_note_seconds.other': 'секунд',
    'js.settings_modal.resend_sms_code_link': 'Отправить SMS-код повторно',
    'js.settings_modal.get_sms_code_button': 'Получить SMS-код',
    'js.settings_modal.change_button': 'Изменить',
    'js.settings_modal.save_button': 'Сохранить',
    'js.settings_modal.contact_us': 'Вопросы? Пишите на {email}',
    'js.settings_modal.validation.password_minlength':
      'Пароль должен быть не менее 1 символа',
    'js.settings_modal.validation.password_equal_to': 'Пароли должны совпадать',
    'js.settings_modal.validation.password_confirmation_required':
      'Введите пароль повторно',
    'js.settings_modal.validation.password_confirmation_minlength':
      'Пароль должен быть не менее 1 символа',
    'js.settings_modal.validation.phone_wrong_format':
      'Неверный формат телефона',
    'js.settings_modal.validation.field_wrong_format':
      'Поле имеет неправильный формат',
    'js.social_button.vkontakte': 'Вконтакте',
    'js.social_button.facebook': 'Facebook',
    'js.flash_notifier.could_not_load_image':
      'Не удалось загрузить изображение',
    'js.flash_notifier.error_has_occurred_try_again':
      'Произошла ошибка! Попробуйте еще раз.',
    'js.flash_notifier.error_has_occurred_refresh_browser':
      'Произошла ошибка! Попробуйте обновить страницу.',
    'js.flash_notifier.error_has_occurred_check_connection':
      'Произошла ошибка! Проверьте соединение с интернетом.',
    'js.flash_notifier.could_not_load_all_ads_try_again':
      'Не удалось загрузить все объявления! Попробуйте ещё раз.',
    'js.flash_notifier.session_is_outdated':
      'Сессия устарела! Попробуйте еще раз восстановить пароль.',
    'js.flash_notifier.something_went_wrong':
      'Что-то пошло не так! Попробуйте чуть позже еще раз. Спасибо',
    'js.flash_notifier.thanks_for_mark':
      'Спасибо за Вашу оценку! Вы помогаете нам сделать сервис лучше',
    'js.flash_notifier.added_to_favorites':
      'Добавлено в \u003ca href="{link}" title="избранное"\u003eизбранное\u003c/a\u003e',
    'js.flash_notifier.there_are_no_panoramas': 'В заданной точке нет панорам.',
    'js.ad_form.extra_option.living_size': 'Жилая площадь',
    'js.ad_form.extra_option.kitchen_size': 'Кухня',
    'js.ad_form.extra_option.apt_number': 'Номер квартиры',
    'js.ad_form.extra_option.build_year': 'Год постройки',
    'js.ad_form.extra_option.ceiling_height': 'Высота потолков',
    'js.ad_form.extra_option.building_type': 'Тип дома',
    'js.ad_form.extra_option.building_types.brick': 'Кирпичный',
    'js.ad_form.extra_option.building_types.monolithic': 'Монолитный',
    'js.ad_form.extra_option.building_types.panel': 'Панельный',
    'js.ad_form.extra_option.building_types.blocky': 'Блочный',
    'js.ad_form.extra_option.building_types.wood': 'Деревянный',
    'js.ad_form.extra_option.building_types.stalinist': 'Сталинский',
    'js.ad_form.extra_option.building_types.monolithic_brick':
      'Монолитно-кирпичный',
    'js.ad_form.extra_option.propetry_type': 'Тип недвижимости',
    'js.ad_form.extra_option.cadastral_number': 'Кадастровый номер',
    'js.ad_form.step': 'Шаг',
    'js.ad_form.or': 'или',
    'js.ad_form.errors.email': 'Email имеет неправильный формат',
    'js.ad_form.errors.field_required': 'Поле обязательно к заполнению',
    'js.ad_form.errors.select_required': 'Необходимо что-то выбрать',
    'js.ad_form.errors.image_required': 'Загрузите хотя бы 1 изображение',
    'js.ad_form.errors.must_be_numeric':
      'Следующие поля должны быть числовыми: Площадь, Этаж, Этажей в доме',
    'js.ad_form.errors.greater_than': 'Введите число больше 0',
    'js.ad_form.errors.phone_wrong_format': 'Неверный формат телефона',
    'js.ad_form.city.label': 'Город',
    'js.ad_form.auth.label': 'Вход или регистрация',
    'js.ad_form.auth.footnotes_register': 'Регистрировались?',
    'js.ad_form.auth.footnotes_sign': 'Войдите',
    'js.ad_form.kind.label': 'Что вы сдаете?',
    'js.ad_form.kind.label_sale': 'Что вы продаете?',
    'js.ad_form.kind.rent_room': 'Комнату',
    'js.ad_form.kind.rent_apartment': 'Квартиру',
    'js.ad_form.kind.type': 'Выберите тип объявления',
    'js.ad_form.kind.agency': 'Кто вы?',
    'js.ad_form.kind.commission': 'Комиссия?',
    'js.ad_form.address.label': 'Введите адрес',
    'js.ad_form.address.placeholder': 'Например, Профсоюзная, 10',
    'js.ad_form.address.footnote':
      'Пожалуйста, убедитесь, что адрес отображается на карте',
    'js.ad_form.subways.label': 'Ближайшее метро',
    'js.ad_form.district.label': 'Выберите район',
    'js.ad_form.title.title': 'Заголовок',
    'js.ad_form.title.placeholder': 'Например: «Трёшка на Патриках».',
    'js.ad_form.description.label': 'Описание',
    'js.ad_form.description.placeholder':
      'Подробное описание – это обязательный параметр объявления. Если не знаете что писать, воспользуйтесь подсказкой на зеленом фоне',
    'js.ad_form.description.footnote':
      '\u003ch5\u003eОписывайте по пунктам:\u003c/h5\u003e\u003cul\u003e\u003cli\u003eДом и квартира (чистый подъезд, высокие потолки)\u003c/li\u003e\u003cli\u003eМебель, бытовая техника, интернет\u003c/li\u003e\u003cli\u003eИнфраструктура: все, что делает жизнь удобной (магазины, поликлиники, школы)\u003c/li\u003e\u003cli\u003eТранспорт и парковка (расстояние до метро/остановки, есть ли парковка у дома)\u003c/li\u003e\u003cli\u003eСоседи и принятый в квартире образ жизни (если сдаете комнату)\u003c/li\u003e\u003c/ul\u003e',
    'js.ad_form.images.label': 'Фотографии',
    'js.ad_form.images.cover': 'Обложка',
    'js.ad_form.images.select_text':
      'Выберите файл на своём компьютере или\u0026nbsp;просто переместите сюда фотографии (потяните за\u0026nbsp;фото, чтобы поменять порядок)',
    'js.ad_form.images.footnote':
      '\u003ch5\u003eТребования к фотографиям:\u003c/h5\u003e\u003cul\u003e\u003cli\u003eОтсутствие на них знаков других сайтов (авито, циан, из рук в руки)\u003c/li\u003e\u003cli\u003eНаличие на них квартиры или комнаты\u003c/li\u003e\u003cli\u003eПриемлемый размер (не менее 800px)\u003c/li\u003e\u003cli\u003eНесколько четких фотографий на которых хорошо видно комнаты, кухню, ванную и тд\u003c/li\u003e\u003c/ul\u003e',
    'js.ad_form.images.select_button': 'Выбрать фотографии',
    'js.ad_form.images.actions.remove': 'Удалить',
    'js.ad_form.images.actions.rotate': 'Повернуть',
    'js.ad_form.sex.label': 'Для кого',
    'js.ad_form.sex.any': 'Не важно',
    'js.ad_form.sex.female': 'Только для девушек',
    'js.ad_form.sex.male': 'Только для парней',
    'js.ad_form.rooms.label': 'Количество комнат',
    'js.ad_form.options.label': 'Параметры',
    'js.ad_form.options.space': 'Площадь',
    'js.ad_form.options.sq': 'м\u003csup\u003e2\u003c/sup\u003e',
    'js.ad_form.options.floor': 'Этаж',
    'js.ad_form.options.floors_amount': 'Этажей в доме',
    'js.ad_form.price.label': 'Цена',
    'js.ad_form.ext.label':
      'Дополнительные параметры (заполнять не обязательно)',
    'js.ad_form.ext.amenities': 'Удобства',
    'js.ad_form.ext.features': 'Особенности',
    'js.ad_form.phone.label': 'Телефон',
    'js.ad_form.phone.footnote':
      'Наши модераторы позвонят Вам для проверки квартиры. Пожалуйста, будьте на связи.',
    'js.ad_form.phone.protect_title': 'Новинка!',
    'js.ad_form.phone.protect_action': 'Защитите свой мобильный',
    'js.ad_form.phone.protect_buy': 'Подключить за',
    'js.ad_form.phone.protect_note':
      'Мы включим временный телефонный номер с переадресацией на ваш мобильный, который позволит вам скрыть свой реальный номер. Когда вы сдадите квартиру, этот временный номер отключится. Таким образом вы сможете защитить свой номер от нежелательных звонков и попадания в базу к риэлторам.',
    'js.ad_form.phone.protect_benefits.first':
      'Специальный номер с\u0026nbsp;переадресацией на\u0026nbsp;ваш телефон',
    'js.ad_form.phone.protect_benefits.second':
      'Настоящий номер скрыт, он\u0026nbsp;не\u0026nbsp;попадет в\u0026nbsp;базы к\u0026nbsp;риэлторам',
    'js.ad_form.phone.protect_benefits.third':
      'Фильтруем до\u0026nbsp;95% нежелательных звонков!',
    'js.ad_form.other_contacts.label':
      'Еще контакты (не\u0026nbsp;обязательно)',
    'js.ad_form.other_contacts.footnote':
      'Вы можете оставить свой емейл, ссылки на социальные сети или любой другой предпочтительный способ',
    'js.ad_form.other_contacts.placeholder':
      'Например: пишите мне на фейсбук facebook.com/myname, после чего созвонимся и договоримся',
    'js.ad_form.plan.label': 'Выберите тариф',
    'js.ad_form.plan.fast.title': 'Сдайте быстрее',
    'js.ad_form.plan.fast.desc':
      'В\u0026nbsp;10\u0026nbsp;раз больше просмотров',
    'js.ad_form.plan.fast.text1': 'Выделено в\u0026nbsp;списке',
    'js.ad_form.plan.fast.text2':
      'Всегда наверху в\u0026nbsp;результатах поиска',
    'js.ad_form.plan.fast.text3':
      '\u003cb class="color-green"\u003eБонус\u003c/b\u003e: модерация за\u0026nbsp;1\u0026nbsp;час(в\u0026nbsp;рабочие дни с\u0026nbsp;11\u0026nbsp;до\u0026nbsp;19)',
    'js.ad_form.plan.fast.access_common': 'Сразу доступно всем посетителям',
    'js.ad_form.plan.fast.access_paid': 'Видно на главной странице',
    'js.ad_form.plan.normal.title': 'Обычное объявление',
    'js.ad_form.plan.normal.access_paid':
      'Доступно всем посетителям через {period} (а\u0026nbsp;сначала\u0026nbsp;— только подписчикам)',
    'js.ad_form.plan.normal.access_common': 'Доступно в общем поиске',
    'js.ad_form.plan.normal.checking': 'Проверяется в\u0026nbsp;общей очереди',
    'js.ad_form.plan.normal.free': 'Бесплатно',
    'js.ad_form.plan.buy': 'Купить',
    'js.ad_form.plan.for': 'за',
    'js.ad_form.actions.save': 'Сохранить',
    'js.ad_form.actions.pend': 'Отправить на модерацию',
    'js.ad_form.editing_note':
      'При редактировании заголовка, описания, номера телефона или фотографий, объявление будет снова отправлено на модерацию',
    'js.offer_gallery.photo': 'фото',
    'js.offer_map.view.map': 'Карта',
    'js.offer_map.view.street': 'Вид с улицы',
    'js.offer_featuring_form.actions.submit': 'Продолжить',
    'js.react_select.no_results': 'Ничего не найдено',
    'js.search_assistance_email_form.text': 'Общий текст',
    'js.search_assistance_email_form.link': 'Ссылка',
    'js.search_assistance_email_form.comment': 'Комментарий',
    'js.search_assistance_email_form.remove': 'Удалить',
    'js.search_assistance_email_form.add': 'Добавить',
    'js.search_assistance_email_form.send': 'Отправить',
    'js.search_assistance_email_form.preview': 'Предпросмотр',
    'js.search_assistance_email_form.close': 'Закрыть',
    'js.search_assistance_email_form.success_alert':
      'Письмо успешно отправлено',
    'js.search_assistance_form.city_id': 'Город',
    'js.search_assistance_form.search_assistant_id': 'Помощник',
    'js.search_assistance_form.lead_id': 'Заявка',
    'js.search_assistance_form.user_id': 'Пользователь',
    'js.search_assistance_form.name': 'Имя',
    'js.search_assistance_form.phone': 'Телефон',
    'js.search_assistance_form.email': 'Емайл',
    'js.search_assistance_form.address': 'Емайл',
    'js.search_assistance_form.comment': 'Комментарий',
    'js.search_assistance_form.rooms_amount': 'Тип',
    'js.search_assistance_form.price_min': 'Цена мин',
    'js.search_assistance_form.price_max': 'Цена макс',
    'js.search_assistance_form.client_fee_max': 'Комиссия макс (%)',
    'js.search_assistance_form.space_min': 'Площадь мин',
    'js.search_assistance_form.space_max': 'Площадь макс',
    'js.search_assistance_form.floor_min': 'Этаж мин',
    'js.search_assistance_form.floor_max': 'Этаж макс',
    'js.search_assistance_form.subject_ids': 'Удобства',
    'js.search_assistance_form.tag_ids': 'Особенности',
    'js.search_assistance_form.amenity_ids': 'Удобства/Особенности',
    'js.search_assistance_form.publishing_period': 'Срок жизни объявления',
    'js.search_assistance_form.expires_at': 'Дата окончания заявки',
    'js.search_assistance_form.subway_path_mode': 'Способ добираться до метро',
    'js.search_assistance_form.path_duration': 'Время до метро в минутах',
    'js.search_assistance_form.remove': 'Удалить',
    'js.search_assistance_form.add': 'Добавить',
    'js.search_assistance_form.repairs': 'Ремонт',
    'js.search_assistance_form.animals': 'Животные',
    'js.search_assistance_form.residents': 'Кто будет проживать в квартире',
    'js.search_assistance_form.resident_work': 'Место работы',
    'js.search_assistance_form.urgency_date': 'Желаемая дата заселения',
    'js.search_assistance_form.urgency_type': 'Как срочно нужно заселиться',
    'js.search_assistance_form.from_homeowner': 'Предложения от',
    'js.search_assistance_form.custom_location': 'Расположение',
    'js.search_assistance_form.priorities': 'Приоритеты',
    'js.search_assistance_form.receive_type': 'Предпочитаемый способ связи',
    'js.search_assistance_form.show_map': 'Показать карту',
    'js.search_assistance_form.close_map': 'Закрыть карту',
    'js.search_assistance_form.save': 'Сохранить',
    'js.buyer_price.open_button': 'Предложить свою цену',
    'js.buyer_price.title': 'Предложите свою цену',
    'js.buyer_price.phone_placeholder': 'Ваш номер телефона',
    'js.buyer_price.validation.phone_wrong_format': 'Неверный формат телефона',
    'js.payment_form.new_card': 'Новая карта',
    'js.payment_form.linked_cards': 'Привязанные карты',
    'js.payment_form.remove_card': 'удалить карту',
    'js.payment_form.add_new_card': 'добавить новую карту',
    'js.payment_form.submit_button': 'Оплатить',
    'js.payment_form.or': 'или',
    'js.payment_form.security_text':
      'Ваши платежные данные защищены технологией PCI DSS',
    'js.payment_form.confirm_remove_card':
      'Вы уверены, что хотите удалить карточку?',
    'js.payment_form.flash_notifier.card_successfully_deleted':
      'Карточка была успешно удалена',
    'js.payment_card.number': 'Номер карты',
    'js.payment_card.expiry': 'Срок действия',
    'js.payment_card.cvc': 'СVC/CVV',
    'js.payment_card.remember_card':
      'Запомнить \u003cspan class="hidden-xs"\u003eкарту\u003c/span\u003e',
    'js.payment_card.placeholders.expiry': 'ММ/ГГ',
    'js.payment_card.errors.number': 'Неверный номер карты',
    'js.payment_card.errors.expiry': 'Неверный срок действия',
    'js.payment_card.tooltips.cvc': 'Три цифры на обратной стороне карты',
    'js.payment_card.tooltips.remember':
      'Сохранить карту для следующих покупок',
  },
};
