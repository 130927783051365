export const BRICK_TYPE = 'brick';
export const MONOLITHIC_TYPE = 'monolithic';
export const PANEL_TYPE = 'panel';
export const BLOCKY_TYPE = 'blocky';
export const WOOD_TYPE = 'wood';
export const STALINIST_TYPE = 'stalinist';
export const MONOLITHIC_BRICK_TYPE = 'monolithic_brick';
export const BUILDING_TYPES = {
  BRICK: BRICK_TYPE,
  MONOLITHIC: MONOLITHIC_TYPE,
  PANEL: PANEL_TYPE,
  BLOCKY: BLOCKY_TYPE,
  WOOD: WOOD_TYPE,
  STALINIST: STALINIST_TYPE,
  MONOLITHIC_BRICK: MONOLITHIC_BRICK_TYPE,
};

export const NONE_TYPE = 'none';
export const COSMETIC_TYPE = 'cosmetic';
export const EURO_TYPE = 'euro';
export const DESIGN_TYPE = 'design';
export const REPAIR_TYPES = {
  NONE: NONE_TYPE,
  COSMETIC: COSMETIC_TYPE,
  EURO: EURO_TYPE,
  DESIGN: DESIGN_TYPE,
};

export const GROUND_TYPE = 'ground';
export const MULTI_LEVEL_TYPE = 'multi_level';
export const UNDERGROUND_TYPE = 'underground';
export const ROOFTOP_TYPE = 'rooftop';
export const PARKING_TYPES = {
  GROUND: GROUND_TYPE,
  MULTI_LEVEL: MULTI_LEVEL_TYPE,
  UNDERGROUND: UNDERGROUND_TYPE,
  ROOFTOP: ROOFTOP_TYPE,
};

export const VIEW_LIST = 'list';
export const VIEW_MAP = 'map';
export const VIEW_MODES = {
  MAP: VIEW_MAP,
  LIST: VIEW_LIST,
};

export const DEAL_TYPE_RENT = 'rent';
export const DEAL_TYPE_DAILY_RENT = 'daily_rent';
export const DEAL_TYPE_SALE = 'sale';
export const DEAL_TYPES = {
  RENT: DEAL_TYPE_RENT,
  DAILY_RENT: DEAL_TYPE_DAILY_RENT,
  SALE: DEAL_TYPE_SALE,
};

export const COMMISSION_FLAG_YES = 'yes';
export const COMMISSION_FLAG_NO = 'no';
export const COMMISSION_FLAGS = {
  YES: COMMISSION_FLAG_YES,
  NO: COMMISSION_FLAG_NO
};

export const KIND_APARTMENT = 'apartment';
export const KIND_ROOM = 'room';
export const KINDS = {
  APARTMENT: KIND_APARTMENT,
  ROOM: KIND_ROOM,
};

export const SEX_ANY = 'any';
export const SEX_FEMALE = 'female';
export const SEX_MALE = 'male';
export const SEX_TYPES = {
  ANY: SEX_ANY,
  FEMALE: SEX_FEMALE,
  MALE: SEX_MALE,
};

export const FILTER_AMENITY_KINDS = {
  TAG: 'tag',
  SUBJECT: 'subject',
};

export const FILTER_KEY_DEAL_TYPE = 'deal_type';
export const FILTER_KEY_KIND = 'kind';
export const FILTER_KEY_ROOM_QUANTITY = 'rooms_amount';
export const FILTER_KEY_SEX = 'sex';
export const FILTER_KEY_GEO_AREAS = 'geo_areas';
export const FILTER_KEY_SUBWAYS = 'subway_ids';
export const FILTER_KEY_DISTRICTS = 'district_ids';
export const FILTER_KEY_ROUTE_TYPE = 'route_type';
export const FILTER_KEY_ROUTE_TIME = 'route_time';
export const FILTER_KEY_PRICE_MIN = 'price_min';
export const FILTER_KEY_PRICE_MAX = 'price_max';
export const FILTER_KEY_VERIFICATION = 'only_verified';
export const FILTER_KEY_REALTOR = 'has_client_fee';
export const FILTER_KEY_REALTOR_COMISSION = 'client_fee';
export const FILTER_KEY_SPACE_MIN = 'space_min';
export const FILTER_KEY_SPACE_MAX = 'space_max';
export const FILTER_KEY_FLOOR_MIN = 'floor_min';
export const FILTER_KEY_FLOOR_MAX = 'floor_max';
export const FILTER_KEY_AMENITIES = 'amenity_ids';
export const FILTER_KEYS = {
  DEAL_TYPE: FILTER_KEY_DEAL_TYPE,
  KIND: FILTER_KEY_KIND,
  ROOM_QUANTITY: FILTER_KEY_ROOM_QUANTITY,
  SEX: FILTER_KEY_SEX,
  GEO_AREAS: FILTER_KEY_GEO_AREAS,
  SUBWAYS: FILTER_KEY_SUBWAYS,
  DISTRICTS: FILTER_KEY_DISTRICTS,
  ROUTE_TYPE: FILTER_KEY_ROUTE_TYPE,
  ROUTE_TIME: FILTER_KEY_ROUTE_TIME,
  PRICE_MIN: FILTER_KEY_PRICE_MIN,
  PRICE_MAX: FILTER_KEY_PRICE_MAX,
  VERIFICATION: FILTER_KEY_VERIFICATION,
  REALTOR: FILTER_KEY_REALTOR,
  REALTOR_COMISSION: FILTER_KEY_REALTOR_COMISSION,
  SPACE_MIN: FILTER_KEY_SPACE_MIN,
  SPACE_MAX: FILTER_KEY_SPACE_MAX,
  FLOOR_MIN: FILTER_KEY_FLOOR_MIN,
  FLOOR_MAX: FILTER_KEY_FLOOR_MAX,
  AMENITIES: FILTER_KEY_AMENITIES,
};
