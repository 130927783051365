import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import AdFormValidatable from './AdFormValidatableMixin';

class AdFormAgency extends React.PureComponent {
  componentDidMount() {
    const { validatePresented } = this.props;

    validatePresented();
  }

  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.AGENCY]: event.currentTarget.value });
  };

  render() {
    const {
      intl: { formatMessage },
      agency,
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsAdFormKindAgency)}
        </div>
        <div className="form-row-content">
          <div className="radio-group radio-group-table" id="ad_input_type">
            <label className="radio radio-flat">
              <input
                className="radio-control"
                type="radio"
                checked={agency == 'false' || agency === false}
                value={false}
                onChange={this.handleChange}
              />
              <span className="radio-content">
                <span className="radio-text">{formatMessage(defaultMessages.jsCatalogFilterOwner)}</span>
              </span>
            </label>

            <label className="radio radio-flat">
              <input
                className="radio-control"
                type="radio"
                checked={agency == 'true' || agency === true}
                value={true}
                onChange={this.handleChange}
              />
              <span className="radio-content">
                <span className="radio-text">{formatMessage(defaultMessages.jsCatalogFilterAgency)}</span>
              </span>
            </label>
          </div>
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormAgency.propTypes = {
  errorMessage: PropTypes.func,
  intl: intlShape.isRequired,
  agency: PropTypes.bool,
  setAdState: PropTypes.func,
  validatePresented: PropTypes.func,
};

export default AdFormValidatable(AdFormAgency, FIELD_NAMES.AGENCY);
