import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { COMMISSION_FLAGS } from '../AdCatalog/AdCatalog.constants';
import { FIELD_NAMES } from './constants';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormRadio from './AdFormRadio';

class AdFormCommissionFlag extends React.PureComponent {
  componentDidMount() {
    const { validatePresented } = this.props;

    validatePresented();
  }

  handleChange = event => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.COMMISSION_FLAG]: event.currentTarget.value });
  };

  render() {
    const {
      intl: { formatMessage },
      commissionFlag,
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label">
          {formatMessage(defaultMessages.jsCatalogFilterCommissionFlag)}
        </div>
        <div className="form-row-content">
          <div className="radio-group radio-group-table" id="ad_input_type">
            <AdFormRadio
              value="no"
              text={formatMessage(defaultMessages.jsCatalogFilterWithoutCommission)}
              checked={commissionFlag === COMMISSION_FLAGS.NO}
              onChange={this.handleChange}
            />
            <AdFormRadio
              value="yes"
              text={formatMessage(defaultMessages.jsCatalogFilterWithCommission)}
              checked={commissionFlag === COMMISSION_FLAGS.YES}
              onChange={this.handleChange}
            />
          </div>
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormCommissionFlag.propTypes = {
  errorMessage: PropTypes.func,
  intl: intlShape.isRequired,
  commissionFlag: PropTypes.string,
  setAdState: PropTypes.func,
  validatePresented: PropTypes.func,
};

export default AdFormValidatable(AdFormCommissionFlag, FIELD_NAMES.COMMISSION_FLAG);
