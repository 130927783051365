import { injectIntl, intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMessages } from '../../../../libs/i18n/default';
import { FIELD_NAMES } from './constants';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import AdFormValidatable from './AdFormValidatableMixin';
import AdFormInputNumeric from './AdFormInputNumeric';

class AdFormCommission extends React.PureComponent {
  componentDidMount() {
    const { validatePresented } = this.props;

    validatePresented();
  }

  handleChange = (event, val) => {
    const { setAdState } = this.props;

    setAdState({ [FIELD_NAMES.COMMISSION]: val });
  };

  render() {
    const {
      intl: { formatMessage },
      commission,
      hasErrors,
      errorMessage,
    } = this.props;

    return (
      <div className="form-row">
        <div className="form-row-label" id="ad_input_price_value">
          {formatMessage(defaultMessages.jsCatalogFilterCommissionAmount)}
        </div>
        <div className="form-row-content">
          <AdFormInputNumeric
            integer={3}
            classMods={['inline']}
            value={commission}
            error={hasErrors()}
            onChange={this.handleChange}
          />
          {errorMessage()}
        </div>
      </div>
    );
  }
}

AdFormCommission.propTypes = {
  errorMessage: PropTypes.func,
  hasErrors: PropTypes.func,
  intl: intlShape.isRequired,
  commission: PropTypes.number,
  setAdState: PropTypes.func,
  validatePresented: PropTypes.func,
};

export default injectIntl(
  AdFormValidatable(AdFormCommission, FIELD_NAMES.COMMISSION),
);
