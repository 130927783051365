import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-flex-modal';

import styles from './styles.scss';

class OfferHowItWorks extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  renderRobotContent = () => {
    return (
      <Fragment>
        <h5 className={styles.title}>Что такое автоматическая проверка</h5>
        <ul className={styles.howList}>
          <li className={styles.howListItem}>
            <span className={styles.howListNumber}>1</span>
            <p className={styles.howListNotice}>
              Специальный алгоритм проверяет, насколько цена соответствует
              площади и местоположению квартиры.
            </p>
          </li>
          <li className={styles.howListItem}>
            <span className={styles.howListNumber}>2</span>
            <p className={styles.howListNotice}>
              Робот выясняет, есть ли жалобы на владельца объявления. Если да,
              мы скрываем его из выдачи.
            </p>
          </li>
        </ul>
      </Fragment>
    );
  };

  renderModeratorContent = () => {
    return (
      <Fragment>
        <h5 className={styles.title}>Как модераторы проверяют объявления</h5>
        <ul className={styles.howList}>
          <li className={styles.howListItem}>
            <span className={styles.howListNumber}>1</span>
            <p className={styles.howListNotice}>
              Мы звоним по указанному номеру и задаем ряд проверочных вопросов,
              чтобы убедиться, что квартиру сдает именно собственник. Если все
              хорошо, объявление попадает на сайт с пометкой «Проверено».
            </p>
          </li>
          <li className={styles.howListItem}>
            <span className={styles.howListNumber}>2</span>
            <p className={styles.howListNotice}>
              При малейших подозрениях модератор запрашивает данные
              свидетельства о собственности на квартиру.
            </p>
          </li>
          <li className={styles.howListItem}>
            <span className={styles.howListNumber}>3</span>
            <p className={styles.howListNotice}>
              Каждые 4 дня после публикации мы звоним повторно и убеждаемся, что
              объявление еще актуально.
            </p>
          </li>
        </ul>
      </Fragment>
    );
  };

  renderAgencyContent = () => {
    return (
      <Fragment>
        <h5 className={styles.title}>Вернем деньги за подписку</h5>
        <ul className={styles.howList}>
          <li className={styles.howListItem}>
            <p className={styles.howListNotice}>
              Если вы арендовали квартиру из данного объявления и вам пришлось оплатить комиссию,
              мы гарантированно вернем вам 2900 рублей за подписку. Кешбэк работает только для пользователей,
              которые купили тариф “Буду искать сам”.
            </p>
          </li>
        </ul>
      </Fragment>
    );
  };

  render() {
    const { isModalOpen } = this.state;
    const { type } = this.props;

    return (
      <Fragment>
        <button
          className="Offer__detailsNoteTextTooltip"
          type="button"
          onClick={this.openModal}
        >
          ?
        </button>
        <Modal transition isOpen={isModalOpen} onClose={this.closeModal}>
          {type === 'robot' && this.renderRobotContent()}
          {type === 'moderator' && this.renderModeratorContent()}
          {type === 'agency' && this.renderAgencyContent()}
          <p className={styles.warning}>
            <span className={styles.warningIcon}>!</span>
            <span className={styles.warningNotice}>
              Важно: никогда не вносите предоплату или залог, прежде чем
              осмотрите квартиру и проверите документы собственника.
            </span>
          </p>
        </Modal>
      </Fragment>
    );
  }
}

OfferHowItWorks.propTypes = {
  type: PropTypes.oneOf(['robot', 'moderator', 'agency']).isRequired,
};

export default OfferHowItWorks;
