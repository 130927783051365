import { intlShape } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import { defaultMessages } from '../../../../libs/i18n/default';
import { mainFilterApi } from '../../api/mainFilter';
import setRailsContext from '../../../common/utils/setRailsContext';
import TranslationProvider from '../../../common/components/HOC/TranslationProvider';
import MainFiltersRegion from './MainFiltersRegion';
import MainFiltersSelectDealTypes from './MainFiltersSelectDealTypes';
import MainFiltersCheckboxKind from './MainFiltersCheckboxKind';
import MainFiltersCheckboxListRooms from './MainFiltersCheckboxListRooms';
import MainFiltersSubmitButton from './MainFiltersSubmitButton';

class MainFilters extends React.Component {
  constructor(props) {
    super(props);

    const { cityId } = this.props;

    this.state = {
      cityId,
      isLoading: false,
      kind: '', // Квартиры и комнаты
      roomQuantity: [],
      dealType: 'rent', // аренда по умолчанию
    };
  }

  handleSubmitButtonClick = event => {
    const { openInNewWindow } = this.props;

    this.toggleLoader(true);

    mainFilterApi.getUrlByFilter(this.serializeData()).then(({ url }) => {
      this.toggleLoader(false);

      if (openInNewWindow) {
        window.open(url);
      } else {
        window.location = url;
      }
    });

    event.preventDefault();
  };

  toggleLoader = bool => {
    const { isLoading } = this.state;

    this.setState({ isLoading: isNil(bool) ? !isLoading : bool });
  };

  updateState = state => {
    this.setState(state);
  };

  serializeData() {
    const { kind, roomQuantity, dealType, cityId } = this.state;

    let data = {
      kind,
      rooms: roomQuantity,
      deal_type: dealType
    };

    return {
      city_id: cityId,
      filter: data,
    };
  }

  render() {
    const { cityId, dealType, kind, roomQuantity, isLoading } = this.state;
    const { intl, cities } = this.props;
    // const { formatMessage } = intl;

    return (
      <div className="MainFilters">
        <div className="MainFilters__cover" />
        <div className="MainFilters__container container">
          <div className="MainFilters__inner">
            <div className="MainFilters__title">
              <span>Поможем найти квартиру мечты&nbsp;</span>
              <MainFiltersRegion
                intl={intl}
                cities={cities}
                cityId={cityId}
                updateState={this.updateState}
              />
            </div>
            <div className="MainFilters__items">
              <div className="MainFilters__item MainFilters__item--metro">
                <MainFiltersSelectDealTypes
                  intl={intl}
                  cityId={cityId}
                  dealType={dealType}
                  updateState={this.updateState}
                />
              </div>
              <div className="MainFilters__item">
                <div className="MainFilters__checkboxGroup">
                  <MainFiltersCheckboxKind
                    intl={intl}
                    kind={kind}
                    updateState={this.updateState}
                  />
                  <MainFiltersCheckboxListRooms
                    intl={intl}
                    roomQuantity={roomQuantity}
                    updateState={this.updateState}
                  />
                </div>
              </div>
              <div className="MainFilters__item MainFilters__item--submit">
                <MainFiltersSubmitButton
                  intl={intl}
                  isDisabled={isLoading}
                  onClick={this.handleSubmitButtonClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MainFilters.propTypes = {
  cities: PropTypes.array.isRequired,
  cityId: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  openInNewWindow: PropTypes.bool.isRequired,
};

export default setRailsContext(TranslationProvider(MainFilters));
